@import '../scssCore/_cssVarsColors';
@import '../scssCore/_cssVariables';


.OTPwithoutSignupCard {
  max-width: 350px;
  margin: 0 auto;
}

.signup-card {
  .OTPwithoutSignupCard {
    max-width:100%;
    margin: auto;
  }
}