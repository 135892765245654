@import '../scssCore/_cssVarsColors';
@import '../scssCore/_cssVariables';

.cstm-modal {
	display: block !important;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 96 !important;
}

.modal-content{
	.alert{
		margin:15px;
	}
	.alert + .modal-body{
		padding-top:0;
	}
	.modal-body {
		.alert{
			margin:0 0 15px 0;
		}
	}
}

.modal-body {
	padding: 15px;
	.page-title-right-action{
		text-align: right;
		margin: 0 -7px !important;
		padding: 15px 15px 0 15px;
		border-top: 1px solid $neutralColorBorderLighter;
	}
}
.modal-footer{
	border-top: 1px solid $neutralColorBorderLighter;
}

.image-viewer,
.pdf-viewer {
	img,
	pdf {
		max-width: 100%;
		height: auto;
		display: block;
	}
}
