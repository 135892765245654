@font-face {
	font-family: 'Minerva2';
	src: url('iconFonts/Minerva.eot?oom8jn');
	src: url('iconFonts/Minerva.eot?oom8jn#iefix') format('embedded-opentype'),
		url('iconFonts/Minerva.ttf?oom8jn') format('truetype'),
		url('iconFonts/Minerva.woff?oom8jn') format('woff'),
		url('iconFonts/Minerva.svg?oom8jn#Minerva') format('svg');
	font-weight: normal;
	font-style: normal;
}

.fIcon,
.editable-buttons .glyphicon {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: 'Minerva2' !important;
	/*speak: none;*/
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1.3;
	display: inline-block;

	/* Enable Ligatures ================ */
	letter-spacing: 0;
	-webkit-font-feature-settings: 'liga';
	-moz-font-feature-settings: 'liga=1';
	-moz-font-feature-settings: 'liga';
	-ms-font-feature-settings: 'liga' 1;
	font-feature-settings: 'liga';
	-webkit-font-variant-ligatures: discretionary-ligatures;
	font-variant-ligatures: discretionary-ligatures;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
	content: '\21';
}
.icon-delete:before {
	content: '\22';
}
.icon-delete2:before {
	content: '\23';
}
.icon-print:before {
	content: '\24';
}
.icon-search:before {
	content: '\25';
}
.icon-refresh:before {
	content: '\26';
}
.icon-arrow_down1:before {
	content: '\27';
}
.icon-arrow_up1:before {
	content: '\28';
}
.icon-arrow_left1:before {
	content: '\29';
}
.icon-arrow_right1:before {
	content: '\2a';
}
.icon-arrow_down2:before {
	content: '\2b';
}
.icon-arrow_up2:before {
	content: '\2c';
}
.icon-arrow_left2:before {
	content: '\2d';
}
.icon-arrow_right2:before {
	content: '\2e';
}
.icon-back2:before {
	content: '\2f';
}
.icon-back1:before {
	content: '\30';
}
.icon-facebook:before {
	content: '\31';
}
.icon-twitter:before {
	content: '\32';
}
.icon-linkedin:before {
	content: '\33';
}
.icon-add1:before {
	content: '\34';
}
.icon-minus1:before {
	content: '\35';
}
.icon-edit1:before {
	content: '\36';
}
.icon-ok1:before {
	content: '\37';
}
.icon-cancel1:before {
	content: '\38';
}
.icon-alert1:before {
	content: '\39';
}
.icon-alert2:before {
	content: '\3a';
}
.icon-all_documents:before {
	content: '\3b';
}
.icon-all_documents2:before {
	content: '\3c';
}
.icon-all_documents3:before {
	content: '\3d';
}
.icon-appointment1:before {
	content: '\3e';
}
.icon-appt_cancle:before {
	content: '\3f';
}
.icon-appt_no_show:before {
	content: '\40';
}
.icon-appt_pending:before {
	content: '\41';
}
.icon-appt_rebook:before {
	content: '\42';
}
.icon-appt_show:before {
	content: '\43';
}
.icon-appt_confirmed:before {
	content: '\44';
}
.icon-check:before {
	content: '\45';
}
.icon-check2:before {
	content: '\46';
}
.icon-radio:before {
	content: '\47';
}
.icon-radio2:before {
	content: '\48';
}
.icon-doc_folder1:before {
	content: '\49';
}
.icon-download2:before {
	content: '\4a';
}
.icon-downloaded:before {
	content: '\4b';
}
.icon-email1:before {
	content: '\4c';
}
.icon-email2:before {
	content: '\4d';
}
.icon-hospitalization:before {
	content: '\4e';
}
.icon-daycare:before {
	content: '\4f';
}
.icon-emergency:before {
	content: '\50';
}
.icon-opd:before {
	content: '\51';
}
.icon-flag1:before {
	content: '\52';
}
.icon-flag2:before {
	content: '\53';
}
.icon-open_orders:before {
	content: '\54';
}
.icon-heart_rate:before {
	content: '\55';
}
.icon-glucose:before {
	content: '\56';
}
.icon-respiration:before {
	content: '\57';
}
.icon-height:before {
	content: '\58';
}
.icon-temperature:before {
	content: '\59';
}
.icon-help:before {
	content: '\5a';
}
.icon-help2:before {
	content: '\5b';
}
.icon-show_password:before {
	content: '\5c';
}
.icon-hide_password:before {
	content: '\5d';
}
.icon-hospital1:before {
	content: '\5e';
}
.icon-language:before {
	content: '\5f';
}
.icon-notifications:before {
	content: '\60';
}
.icon-notifications2:before {
	content: '\61';
}
.icon-info:before {
	content: '\62';
}
.icon-info2:before {
	content: '\63';
}
.icon-menu:before {
	content: '\65';
}
.icon-op_summary1:before {
	content: '\66';
}
.icon-op_summary2:before {
	content: '\67';
}
.icon-patient_overview1:before {
	content: '\68';
}
.icon-discharge_summary1:before {
	content: '\69';
}
.icon-discharge_summary2:before {
	content: '\6a';
}
.icon-patient_overview2:before {
	content: '\6b';
}
.icon-pending:before {
	content: '\6c';
}
.icon-profile1:before {
	content: '\6d';
}
.icon-profile2:before {
	content: '\6e';
}
.icon-profile3:before {
	content: '\e900';
}
.icon-questionnaire1:before {
	content: '\6f';
}
.icon-questionnaire2:before {
	content: '\70';
}
.icon-questionnaire3:before {
	content: '\71';
}
.icon-lab_test:before {
	content: '\72';
}
.icon-prescription:before {
	content: '\73';
}
.icon-radiology:before {
	content: '\74';
}
.icon-radiology_reports:before {
	content: '\75';
}
.icon-radiology_reports2:before {
	content: '\76';
}
.icon-rate_card:before {
	content: '\77';
}
.icon-record:before {
	content: '\78';
}
.icon-report_shared:before {
	content: '\79';
}
.icon-report_shared2:before {
	content: '\7a';
}
.icon-settings3:before {
	content: '\7b';
}
.icon-settings32:before {
	content: '\7c';
}
.icon-upload_document1:before {
	content: '\7d';
}
.icon-upload_document2:before {
	content: '\7e';
}
.icon-upload_folder1:before {
	content: '\7f';
}
.icon-upload_folder2:before {
	content: '\80';
}
.icon-settings4:before {
	content: '\81';
}
.icon-zip_files:before {
	content: '\82';
}
.icon-lab_report2:before {
	content: '\a1';
}
.icon-lab_report3:before {
	content: '\a2';
}
.icon-image:before {
	content: '\a3';
}
.icon-images:before {
	content: '\a4';
}
.icon-more1:before {
	content: '\a5';
}
.icon-more2:before {
	content: '\a6';
}
.icon-graph:before {
	content: '\a7';
}
.icon-care_plan:before {
	content: '\a8';
}
.icon-identifier:before {
	content: '\a9';
}
.icon-unique_id:before {
	content: '\aa';
}
.icon-time:before {
	content: '\ab';
}
.icon-published:before {
	content: '\ad';
}
.icon-upload:before {
	content: '\ae';
}
.icon-repeat:before {
	content: '\af';
}
.icon-repeat-02:before {
	content: '\b0';
}
.icon-all_documents_02:before {
	content: '\b1';
}
.icon-appointment3:before {
	content: '\b2';
}
.icon-delete_02:before {
	content: '\b3';
}
.icon-ok_02:before {
	content: '\b4';
}
.icon-sort_ascending:before {
	content: '\139';
}
.icon-sort_descending:before {
	content: '\140';
}
.icon-View_More:before {
	content: '\141';
}
.icon-View_Less:before {
	content: '\142';
}
.icon-favourites_01:before {
	content: '\143';
}
.icon-favourites_02:before {
	content: '\144';
}
.icon-favourites_global_filter:before {
	content: '\145';
}
.icon-favourites_03:before {
	content: '\146';
}
.icon-emergency2:before {
	content: '\147';
}
.icon-call:before {
	content: '\148';
}
.icon-more_ver_01:before {
	content: '\149';
}
.icon-more_ver_02:before {
	content: '\150';
}
.icon-care_plan_02:before {
	content: '\151';
}
.icon-Temperature02:before {
	content: '\152';
}
.icon-Glucose02:before {
	content: '\153';
}
.icon-RespirationRate:before {
	content: '\154';
}
.icon-Height02:before {
	content: '\155';
}
.icon-Weight:before {
	content: '\156';
}
.icon-BMI:before {
	content: '\157';
}
.icon-BloodGlucose:before {
	content: '\158';
}
.icon-Filter_01:before {
	content: '\159';
}
.icon-Filter_02:before {
	content: '\160';
}
.icon-CopyURL:before {
	content: '\161';
}
.icon-Link:before {
	content: '\162';
}
.icon-clinical_note:before {
	content: '\163';
}
.icon-clinical_note-02:before {
	content: '\164';
}
.icon-share:before {
	content: '\175';
}
.icon-profile_04:before {
	content: '\176';
}
.icon-profile_05:before {
	content: '\177';
}
.icon-patients:before {
	content: '\178';
}
.icon-patients_02:before {
	content: '\179';
}
.icon-graph2:before {
	content: '\180';
}
.icon-list:before {
	content: '\181';
}
.icon-logout:before {
	content: '\182';
}
.icon-recording:before {
	content: '\183';
}
.icon-screenshare:before {
	content: '\184';
}
.icon-audio:before {
	content: '\185';
}
.icon-audiooff:before {
	content: '\186';
}
.icon-chat_A:before {
	content: '\187';
}
.icon-clock:before {
	content: '\188';
}
.icon-home_A:before {
	content: '\189';
}
.icon-notes:before {
	content: '\190';
}
.icon-video:before {
	content: '\191';
}
.icon-video_off:before {
	content: '\192';
}
.icon-status_A:before {
	content: '\193';
}
.icon-stop_screenshare:before {
	content: '\194';
}
.icon-break_the_glass:before {
	content: '\195';
}
.icon-full_screen:before {
	content: '\196';
}
.icon-exit_full_screen:before {
	content: '\197';
}
.icon-inpatient:before {
	content: '\198';
}
.icon-ambulatory:before {
	content: '\199';
}
.icon-outpatient:before {
	content: '\200';
}
.icon-virtual:before {
	content: '\201';
}
.icon-home:before {
	content: '\202';
}
.icon-field_02:before {
	content: '\203';
}
.icon-field_01:before {
	content: '\204';
}
.icon-other:before {
	content: '\205';
}
.icon-unavailable:before {
	content: '\206';
}
.icon-daytime:before {
	content: '\207';
}
.icon-today:before {
	content: '\208';
}
.icon-physical_location:before {
	content: '\209';
}
.icon-logical_location:before {
	content: '\210';
}
.icon-upload_new:before {
	content: '\211';
}
.icon-edit_fill:before {
	content: '\212';
}
.icon-verify_fill:before {
	content: '\213';
}
.icon-camera:before {
	content: '\214';
}
.icon-Payment:before {
	content: '\215';
}
.icon-Rupess:before {
	content: '\216';
}
.icon-Location2:before {
	content: '\217';
}
.icon-id:before {
	content: '\218';
}
.icon-lock:before {
	content: '\219';
}
.icon-Add:before {
	content: '\220';
}
.icon-Expertise:before {
	content: '\221';
}
.icon-Experience:before {
	content: '\222';
}
.icon-Education:before {
	content: '\223';
}
.icon-Publications:before {
	content: '\224';
}
.icon-bookappoint:before {
	content: '\225';
}
.icon-bills:before {
	content: '\226';
}
.icon-myappointments:before {
	content: '\227';
}
.icon-mydocuments_1:before {
	content: '\228';
}
.icon-xray_1:before {
	content: '\229';
}
.icon-sample_1:before {
	content: '\230';
}
.icon-lab_reports_1:before {
	content: '\231';
}
.icon-appointment_1-copy-4:before {
	content: '\232';
}
.icon-bookhealth:before {
	content: '\233';
}
.icon-booknurse:before {
	content: '\234';
}
.icon-findhospital:before {
	content: '\e90c';
}
.icon-physio_1:before {
	content: '\236';
}
.icon-Merge_patient:before {
	content: '\237';
}
.icon-radioreports:before {
	content: '\238';
}
.icon-criticalcare:before {
	content: '\239';
}
.icon-doctor:before {
	content: '\240';
}
.icon-healthattend:before {
	content: '\241';
}
.icon-medicalequipment:before {
	content: '\243';
}
.icon-info_03:before {
	content: '\244';
}
.icon-radiology-02:before {
	content: '\245';
}
.icon-PDF-file:before {
	content: '\246';
}
.icon-PNG_image:before {
	content: '\247';
}
.icon-JPG_image:before {
	content: '\248';
}
.icon-word-document:before {
	content: '\249';
}
.icon-XML:before {
	content: '\250';
}
.icon-text-file:before {
	content: '\251';
}
.icon-zip_file:before {
	content: '\252';
}
.icon-MP4:before {
	content: '\253';
}
.icon-files:before {
	content: '\254';
}
.icon-folder:before {
	content: '\255';
}
.icon-self_check_in:before {
	content: '\256';
}
.icon-map1:before {
	content: '\257';
}
.icon-check1:before {
	content: '\258';
}
.icon-Referral:before {
	content: '\e901';
}
.icon-mobile:before {
	content: '\259';
}
.icon-old_phone:before {
	content: '\260';
}
.icon-forward:before {
	content: '\261';
}
.icon-reply:before {
	content: '\262';
}
.icon-reply_to_all:before {
	content: '\263';
}
.icon-oxyg_saturation:before {
	content: '\264';
}
.icon-otherFile:before {
	content: '\265';
}
.icon-surgery-docs:before {
	content: '\266';
}
.icon-surgery-insurance:before {
	content: '\267';
}
.icon-surgery-user:before {
	content: '\268';
}
.icon-surgery-pen:before {
	content: '\269';
}
.icon-notification-reminder:before {
	content: '\270';
}
.icon-notification-notification:before {
	content: '\271';
}
.icon-patient_visit:before {
	content: '\272';
}
.icon-Print:before {
	content: '\273';
}
.icon-Submit_Query:before {
	content: '\274';
}
.icon-Pending-Request:before {
	content: '\275';
}
.icon-Confirm-Appointment:before {
	content: '\276';
}
.icon-Instagram:before {
	content: '\277';
}
.icon-YouTube:before {
	content: '\278';
}
.icon-My-Family:before {
	content: '\279';
}
.icon-Appointments:before {
	content: '\280';
}
.icon-Inbox:before {
	content: '\281';
}
.icon-Task:before {
	content: '\282';
}
.icon-camera-icon:before {
	content: '\283';
}
.icon-Split-View:before {
	content: '\284';
}
.icon-notification_manager:before {
	content: '\285';
}
.icon-profile_iconsmoon:before {
	content: '\286';
}
.icon-FAQs:before {
	content: '\287';
}
.icon-my-health:before {
	content: '\288';
}
.icon-emergency1:before {
	content: '\289';
}
.icon-Care-Plan:before {
	content: '\290';
}
.icon-covid-19:before {
	content: '\291';
}
.icon-pharmacy-delivery:before {
	content: '\292';
}
.icon-Doctor-Consultation1:before {
	content: '\293';
}
.icon-Nurse:before {
	content: '\294';
}
.icon-access:before {
	content: '\295';
}
.icon-Virtual-encounter:before {
	content: '\296';
}
.icon-fab:before {
	content: '\297';
}
.icon-tele-consult:before {
	content: '\298';
}
.icon-Virtual-waiting-room:before {
	content: '\299';
}
.icon-My-Family2:before {
	content: '\400';
}
.icon-verifyDoc:before {
	content: '\401';
}
.icon-alert3:before {
	content: '\402';
}
.icon-Allergy-Specialist:before {
	content: '\403';
}
.icon-Audiological-Medicine:before {
	content: '\404';
}
.icon-Audiology:before {
	content: '\405';
}
.icon-Wellness-Medicine:before {
	content: '\406';
}
.icon-Venereology:before {
	content: '\407';
}
.icon-Vascular-Surgery:before {
	content: '\408';
}
.icon-Urology:before {
	content: '\409';
}
.icon-Stomach-and-Digestion:before {
	content: '\410';
}
.icon-Sexology:before {
	content: '\411';
}
.icon-Rehabilitation:before {
	content: '\412';
}
.icon-Pulmonology:before {
	content: '\413';
}
.icon-Psychotherapy:before {
	content: '\414';
}
.icon-Psychiatry:before {
	content: '\415';
}
.icon-Physiotherapy:before {
	content: '\416';
}
.icon-Paediatrics:before {
	content: '\417';
}
.icon-Otolaryngology:before {
	content: '\418';
}
.icon-Osteopathy-Specialist:before {
	content: '\419';
}
.icon-Orthopedics-and-Traumatology:before {
	content: '\420';
}
.icon-Orthodontist:before {
	content: '\421';
}
.icon-ophthalmology:before {
	content: '\422';
}
.icon-ophthalmology-eye-care:before {
	content: '\423';
}
.icon-oncology:before {
	content: '\424';
}
.icon-Obstetrics-And-Gynaecology:before {
	content: '\425';
}
.icon-Nutritionist:before {
	content: '\426';
}
.icon-Neurology:before {
	content: '\427';
}
.icon-Naturopathy:before {
	content: '\428';
}
.icon-Mental-health:before {
	content: '\429';
}
.icon-Kidney-and-Urine:before {
	content: '\430';
}
.icon-Internal-Medicine:before {
	content: '\431';
}
.icon-Infertility:before {
	content: '\432';
}
.icon-Gynaecology:before {
	content: '\433';
}
.icon-General-Medicine:before {
	content: '\434';
}
.icon-Fitness-Expert:before {
	content: '\435';
}
.icon-Dietician:before {
	content: '\436';
}
.icon-Diabetology:before {
	content: '\437';
}
.icon-Dermatology:before {
	content: '\438';
}
.icon-Dentistry:before {
	content: '\439';
}
.icon-Cosmetology:before {
	content: '\440';
}
.icon-Child-Health:before {
	content: '\441';
}
.icon-Cardiology:before {
	content: '\442';
}
.icon-Ayurveda-Specialist:before {
	content: '\443';
}
.icon-Right-Tick:before {
	content: '\444';
}
.icon-notification-triggers:before {
	content: '\445';
}
.icon-notification-templates:before {
	content: '\446';
}
.icon-fee:before {
	content: '\447';
}
.icon-prescription2:before {
	content: '\448';
}
.icon-prescription3:before {
	content: '\451';
}
.icon-homeService:before {
	content: '\452';
}
.icon-labTestHome:before {
	content: '\453';
}
.icon-plan-of-treatment:before {
	content: '\454';
}
.icon-head-circumference:before {
	content: '\455';
}
.icon-weight-for-length-percentile:before {
	content: '\456';
}
.icon-inhaled-oxygen-concentration:before {
	content: '\457';
}
.icon-bmi-percentile:before {
	content: '\458';
}
.icon-icon_percent:before {
	content: '\459';
}
.icon-View_Orders:before {
	content: '\460';
}
.icon-View_Results:before {
	content: '\461';
}
.icon-location_pointer:before {
	content: '\462';
}
.icon-EHR:before {
	content: '\463';
}

.icon-document-with-user:before {
	content: '\464';
}

.icon-default-vitals:before {
	content: '\465';
}

.icon-number-of-steps:before {
	content: '\466';
}

.icon-calories-burnt:before {
	content: '\467';
}

.icon-level-of-consciousness:before {
	content: '\468';
}
