// Last updated on Mon, 20 Jan 2025 08:33:53 GMT

$linkTextColor:#042B4A;
$primaryColor:#2A4E69;
$secondaryColor:#042B4A;
$tertiaryColor:#E2EAF1;
$avatarColor:#E2EAF1;
$titleColor:#000000;
$primaryButtonColor:#FFFFFF;
$secondaryButtonColor:#000000;
$bodyTextColor:#333333;
$hintTextColor:#767676;
$labelColor:#757575;
$reverseColor:#FFFFFF;
$reverseTextColor:#FFFFFF;
$neutralColor:#000000;
$tooltipColor:#333333;
$tooltipHeadColor:#666666;
$neutralColorBorder:#919191;
$neutralColorBorderLight:#CCCCCC;
$neutralColorBorderLighter:#E6E6E6;
$neutralColorBackground:#F7F7F7;
$neutralColorBackgroundLight:#F7F7F7;
$informationColor:#B5B5B5;
$informationBackgroundColor:#B5B5B5;
$informationForegroundColor:#333333;
$successColor:#28803D;
$successBackgroundColor:#28803D;
$successForegroundColor:#FFFFFF;
$warningColor:#F7E28F;
$warningBackgroundColor:#F7E28F;
$warningForegroundColor:#333333;
$errorColor:#D13225;
$errorBackgroundColor:#D13225;
$errorColorDark:#DC747F;
$errorForegroundColor:#FFFFFF;
$cardBackgroundColor:#FFFFFF;
$textOnNeutralBackground:#555555;
$textOnNeutralBackgroundDark:#000000;
$textOnNeutralLightBackground:#555555;
$textOnNeutralLightBackgroundDark:#000000;
$textOnCardViewBackground:#555555;
$textOnCardViewBackgroundDark:#000000;
$textOnPrimary:#FFFFFF;
$textOnSecondary:#FFFFFF;
$textOnTertiary:#2A4E69;
$textOnAvatar:#2A4E69;
$primaryColorLight:#376589;
$primaryColorDark:#26465f;
$secondaryColorLight:#053860;
$secondaryColorDark:#042743;
$tertiaryColorLight:#ffffff;
$tertiaryColorDark:#cbd3d9;