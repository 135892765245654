@font-face {
  font-family: 'OpenSansRegular';
  src: url('../fonts/OpenSans-Regular.eot');
  src: url('../fonts/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Regular.woff') format('woff'),
  url('../fonts/OpenSans-Regular.ttf') format('truetype'),
  url('../fonts/OpenSans-Regular.svg#OpenSansRegular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSansSemiBold';
  src: url('../fonts/OpenSans-Semibold.eot');
  src: url('../fonts/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Semibold.woff') format('woff'),
  url('../fonts/OpenSans-Semibold.ttf') format('truetype'),
  url('../fonts/OpenSans-Bold.svg#OpenSansSemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSansBold';
  src: url('../fonts/OpenSans-Bold.eot');
  src: url('../fonts/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Bold.woff') format('woff'),
  url('../fonts/OpenSans-Bold.ttf') format('truetype'),
  url('../fonts/OpenSans-Bold.svg#OpenSansBold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSansLight';
  src: url('../fonts/OpenSans-Light.eot');
  src: url('../fonts/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Light.woff') format('woff'),
  url('../fonts/OpenSans-Light.ttf') format('truetype'),
  url('../fonts/OpenSans-Light.svg#OpenSansLight') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSansExtrabold';
  src: url('../fonts/OpenSans-ExtraBold.eot');
  src: url('../fonts/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Extrabold.woff') format('woff'),
  url('../fonts/OpenSans-ExtraBold.ttf') format('truetype'),
  url('../fonts/OpenSans-Extrabold.svg#OpenSansExtrabold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSansItalic';
  src: url('../fonts/OpenSans-Italic-webfont.eot');
  src: url('../fonts/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans-Italic-webfont.woff') format('woff'),
  url('../fonts/OpenSans-Italic-webfont.ttf') format('truetype'),
  url('../fonts/OpenSans-Italic-webfont.svg#OpenSansItalic') format('svg');
  font-weight: normal;
  font-style: normal;
}
