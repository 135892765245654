@import '../../../scssCore/cssVarsColors';

.updateProfile {
    max-width: 1300px;
}

.updateProfile .pp-boxOuter {
    width: 100px;
    margin: auto;
}

.updateProfile .pp-boxOuter .radius100 pp {
    border-radius: 100%;
}

.updateProfile .pp-boxOuter .pp-box {
    height: 100px;
    width: 100px;
    vertical-align: middle;
    font-size: 2.86em;
}

.updateProfile .pp-boxOuter .pp-box img {
    min-width: 100%;
    min-height: 100%;
    height: auto;
    border-radius: 100%;
    max-height: 100px;
}
.pp-box img {
    border: 0px solid $neutralColorBorderLight;
}
.updateProfile .pp-boxOuter {
    position: relative;
    top: -25px;
    right: -75px;
    cursor: pointer;
}

.updateProfile {
    max-width: 100%;
}

.updateProfile {
    max-width: 100%;
}

.icon-upload_new:before {
    content: "\211";
}


.imageUploaderBtn{
    display: none;
}
.errorText{
    color: red;
    font-weight: 500;
}