// Break Points
$break_xs: 390px;
$break_s: 576px;
$break_m: 768px;
$break_l: 992px;
$break_xl: 1200px;
$break_xxl: 1600px;

// Break Points - reverse
$breakR_xl: 1599.98px;
$breakR_l: 1199.98px;
$breakR_m: 991.98px;
$breakR_s: 767.98px;
$breakR_xs: 575.98px;
$breakR_xxs: 389.98px;

// ORNAMENTAL

// font size
$fs_s: 0.75rem;             // small                   12px
$fs_m: 0.875rem;            // p / body text           14px

$fs_l: 0.875rem;            // h5, h6                  14px
$fs_xl: 1rem;               // h3, h4 /body text large 16px
$fs_xxl: 1.125rem;          // h2                      18px
$fs_xxxl: 1.375rem;         // h1                      22px

$fs_big: 2rem;              // large text              32px
$fs_bigger: 2.5rem;         // big text                40px
$fs_biggest: 3rem;          // bigger text             48px

// font weight
$fw_300:300;
$fw_400:400;
$fw_500:500;
$fw_600:600;
$fw_900:900;

// Line Height

$lh_s: 1.125rem;            // small                   18px
$lh_m: 1.25rem;             // p / body text           20px

$lh_l: 1.25rem;             // h5, h6                  20px
$lh_xl: 1.5rem;             // h3, h4                  24px
$lh_xxl: 1.75rem;           // h2                      28px
$lh_xxxl: 2.375rem;          // h1                      38px

$lh_big: 3rem;              // large text              48px
$lh_bigger: 3.75rem;        // big text                60px
$lh_biggest: 4.5rem;        // bigger text             72px

// font family
$ff_arial:Arial, Helvetica, sans-serif;
$ff_timesNewRoman:Times New Roman, Times, Georgia, serif;
$ff_opensansLight:'OpenSansLight';
$ff_opensans:'OpenSansRegular','Open Sans',Arial, Helvetica, sans-serif;
$ff_opensansSemiBold:'OpenSansSemiBold';
$ff_opensansBold:'OpenSansBold';
$ff_opensansExtraBold:'OpenSansExtrabold';
$ff_opensansItalic:'OpenSansItalic';

// radius
$radius_2:0.125rem;
$radius_2b:0 0 0.125rem 0.125rem;
$radius_tb:0.125rem 0.125rem 0 0;

$radius_4:0.25rem; // Default Radius
$radius_4b:0 0 0.25rem 0.25rem;
$radius_4t:0.25rem 0.25rem 0 0;

$radius_8:0.5rem;

$radius_100:100%; // Rounded

// shadow
$shadow_button:0px 2px 3px rgba(0,0,0,.15); // to be used in buttons
$shadow_avatar:1px 2px 3px rgba(0,0,0,.15); // to be used in avatar
$shadow_tooltip:1px 3px 6px rgba(0,0,0,.30); // to be used in tooltip
$shadow_dropdown:1px 3px 6px rgba(1,3,6,.30); // to be used in dropdown
$shadow_modal:0px 2px 4px rgba(0,0,0,.40); // to be used in modal/popups
$shadow_box:0px 0px 3px rgb(0 0 0 / 15%); // to be used in box
$shadow_box2:0px 0px 4px rgb(0 0 0 / 20%); // to be used in my family

//-----------------------------------------------------------------------------------

// outer padding/margins
$padding_5:5px;
$padding_10:10px;
$padding_15:15px;
$padding_25:25px;
$padding_10_15:10px 15px;
$padding_15_10:15px 10px;
$padding_5_10:5px 10px;
$padding_15_0:15px 0px;

$margin_15:15px;
$margin_left_15:15px;
$margin_right_15:15px;
$margin_top_15:15px;
$margin_bottom_15:15px;

$margin_10:10px;
$margin_left_10:10px;
$margin_right_10:10px;
$margin_top_10:10px;
$margin_bottom_10:10px;

$margin_15R:-15px;
$margin_left_15R:-15px;
$margin_right_15R:-15px;
$margin_top_15R:-15px;
$margin_bottom_15R:-15px;

$margin_10R:10px;
$margin_left_10R:-10px;
$margin_right_10R:-10px;
$margin_top_10R:-10px;
$margin_bottom_10R:-10px;

$margin_10_15R:-10px -15px;
$margin_5_10R:-5px -10px;



// COLORS

$textOnNeutralLightBackgroundDark:#202020;

// file colors
$file_pdf:#ED5565;
$file_image:#AC92EC;

// outline colors
$outlineColor:#CCA8FF;


// widget title colors
$color-widget_1:#097B70;
$color-widget_2:#945E3A;
$color-widget_3:#006C9E;
$color-widget_4:#984874;
$color-widget_5:#4B6D7C;
$color-widget_6:#C72400;
$color-widget_7:#6660B4;
$color-widget_8:#9D3469;
$color-widget_9:#567043;
$color-widget_10:#915E2B;
$color-widget_11:#845306;
