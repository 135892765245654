@import '../../ui/comps/src/scssCore/_cssVarsColors';
@import '../../ui/comps/src/scssCore/_cssVariables';

// COMMON STYLE

.outer-box-right {
	.patientListTable {
		table {
			tr {
				th,
				td {
					.d-lg-none.text-label {
						display: none !important;
					}
					text-align: left;
					&:nth-child(1) {
						width: 100px;
						text-align: center;
					}
					&:nth-child(4) {
						width: 100px;
						text-align: center;
						@media screen and (max-width: $breakR_m) {
							padding-bottom: 10px !important;
						}
					}
				}
			}
		}
	}
}
.noBtnStyle.tooltipLink {
	color: $tooltipColor;
}
