@import '../../../scssCore/cssVarsColors';
@import '../../../scssCore/_cssVariables';

// FILE UPLOADER SPECIFIC CSS


.uploaderBox {
  padding: 50px 15px;
  .fileIcon{
    background: $reverseColor url(../../../../../../assets/images/uploaderImg.png) no-repeat top;
    width: 120px;
    height: 88px;
    display: inline-block;
    background-size: cover;
    margin-bottom: 10px;
    @media screen and (max-width: $breakR_s) {
      width: 90px;
      height: 66px;
    }
  }
}


.boxStyle--dashed--blue {
  border: 1px dashed $primaryColor;
  background: $reverseColor;

  @media screen and (max-width: $breakR_m) {
    box-shadow: $shadow_box;
    margin-bottom:15px;
    border:1px solid $primaryColor !important;
    border-radius:$radius_4;
  }

}

.bulkEditFilter {
  border: 1px solid $neutralColorBorderLighter;
  padding-bottom: 0;
  background: $reverseColor;
}

.uploaderGuidelines{
  background:$neutralColorBackgroundLight;
  border:1px solid $neutralColorBorderLight;
  padding:50px 15px;

  h3{
    text-transform: none;
    margin-bottom: 15px;
    color:$primaryColor;
  }

  @media screen and (max-width: $breakR_m) {
    box-shadow: $shadow_box;
    background: $neutralColorBackground;
    border:1px solid transparent;
    border-radius:$radius_4;
  }

}

.stepBox {
  max-width: 700px;
  margin: auto;
  position: relative;
  min-height: 50px;
  z-index: -0;

  .stepLine {
    height: 4px;
    position: absolute;
    background: $primaryColor;
    right: 12.5%;
    left: 12.5%;
    z-index: 1;
    top: 23px;
  }

  .stepTable {
    position: relative;
    z-index: 2;
    min-height: 50px;

    table {
      margin: 0;
      border: 0;
      width: 100%;

      .icon {
        display: inline-block;
        border: 1px solid $primaryColor;
        background: $reverseColor;
        color: $primaryColor;
        width: 50px;
        height: 50px;
        border-radius: 10px;


        @media screen and (max-width: $breakR_s) {
          width: 40px;
          height: 40px;
        }

        .fIcon {
          font-size: 32px;
          line-height: 48px;
          @media screen and (max-width: $breakR_s) {
            font-size: 24px;
            line-height: 38px;
          }
        }
      }

      td {
        width: 25% !important;
      }

      .stepText {
        white-space: nowrap;
        margin-top: 10px;
        @media screen and (max-width: $breakR_s) {
          font-size: 11px;
        }
      }

    }
  }

}

.uploadDocumentTable {
  .typeIcon {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: grey;
    color: #fff;
    line-height: 18px;
    text-align: center;
    margin-right: 6px;
  }
  .typeIcon.icon-PDF-file {
    background: $color-widget_6;
  }
  .typeIcon.icon-PNG_image,.icon-otherFile {
    background: $color-widget_1;
  }
  .typeIcon.icon-JPG_image {
    background: $color-widget_3;
  }
}
.previewSection{
  padding: 0 8px;
  .boxStyle--dashed--blue{
    padding: 15px 25px;
  }
}

.uploadDocumentTable {
  @media screen and (max-width: $break_l) {
    tbody tr td:nth-child(1), tbody tr td:nth-child(2) {
      padding-right: 130px !important;
    }
  }
}

.uploadButtons {
  text-align: right;

  @media screen and (max-width: $break_m){
    text-align: left;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
}