@import '../../ui/comps/src/scssCore/_cssVarsColors';
@import '../../ui/comps/src/scssCore/_cssVariables';

.legends {
  div {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;

    @media screen and (max-width: $break_m) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  div > span {
    margin-right: 5px;

    &.iconSize {
      display: inline;
      vertical-align: middle;
    }
  }

  div {
    .alertIcon {
      color: $errorColor;
      opacity: 0.8;
    }

    .reminderIcon {
      color: $warningColor;
      opacity: 0.8;
    }

    .informationIcon {
      color: $informationColor;
      opacity: 0.8;
    }
  }
}

.newNotification {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 15px;
}

.newNotification ul,
.newNotification ul li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.newNotification ul {
  border-top: 1px solid $neutralColorBorderLighter;
}

.newNotification ul li {
  border-bottom: 1px solid $neutralColorBorderLighter;
  padding: 10px 100px 10px 10px;
  position: relative;
  cursor: pointer;

  button.notificationBlock {
    display: block;
    text-decoration: none;
    color: $bodyTextColor;
    padding: 0;
    border: 0;
    background: transparent;
    font-weight: normal;
    text-align: left;
    white-space: normal;
    font-family: $ff_opensans;
    box-shadow: none;
    height: auto;

    &:hover {
      box-shadow: none;
    }
  }

  @media screen and (max-width: $break-xs) {
    padding-right: 80px;
  }
}

.newNotification ul li.unread {
  background: $neutralColorBackgroundLight;
}

.newNotification ul li {
  &:hover {
    background: $reverseColor;
  }
}

.newNotification ul li {
  padding-left: 60px;

  em {
    margin-left: -50px;
    font-size: 40px;
    line-height: 40px;
  }

  em.alertIconList {
    color: $errorColor;
    opacity: 0.8;
  }

  em.reminderIconList {
    color: $warningColor;
    opacity: 0.8;
  }

  em.informationIconList {
    color: $informationColor;
    opacity: 0.8;
  }

  .notifyTitle {
    font-size: $fs_m;
    word-break: break-word;

    .form-item {
      a {
        display: inline;
        padding: 0;
        background: transparent;
        color: $bodyTextColor;
        text-decoration: none;
      }
    }
  }

  .notifyTime {
    font-size: $fs_m;
    color: $hintTextColor;

    .iconSize {
      font-size: $fs_xl;
      display: inline;
      vertical-align: middle;
    }
  }
}

.newNotification.headerNotificationDD ul li {
  em {
    margin-left: -45px;
    font-size: 40px;
    line-height: 40px;
  }
}

.newNotification.headerNotificationDD ul li.unread .noti_ttl {
  font-family: $ff_opensans;
}

.mark_read {
  position: absolute;
  right: 5px;
  top: 15px;
  text-align: center;
  width: 90px;

  @media screen and (max-width: $break_xs) {
    width: 70px;
  }
}

.mark_read button {
  color: $neutralColorBorder !important;
  position: relative;
}

.circle {
  display: block;
  font-size: $fs_m;
  font-family: $ff-opensansSemiBold;
  font-weight: normal;
  border-radius: 100%;
  padding: 2px 5px;
  background: $reverseColor;
  color: $secondaryColor;
  position: absolute;
  z-index: 1;
  min-width: 20px;
  line-height: 16px;
  right: 4px;
  top: 10%;
  text-align: center;
}

.dropdown-menu {
  &.newNotification {
    width: 400px;
    min-height: 70px;
    position: absolute !important;
    right: 0;

    @media screen and (max-width: $break_l) {
      width: 300px;
    }

    .notification-dropdownHeader {
      padding: $padding_10_15;
      font-family: $ff_opensansSemiBold;
    }

    .notificationScroll {
      min-height: 250px;
      max-height: 250px;
      overflow-y: scroll;

      li {
        padding-right: 50px;

        .mark_read {
          width: 45px;
          background: transparent;
          button {
            text-align: center;
            background: transparent;
            opacity: 0;
          }
        }

        &:hover,
        &:focus {
          .mark_read {
            button {
              opacity: 1;
            }
          }

          button,
          .btn {
            background: transparent;
          }

          .mark_read {
            button {
              background: transparent;
            }
            &:hover {
              background: transparent;
            }
          }
        }
      }
    }
  }

  .notification-viewAllLink {
    padding: $padding_10_15;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    text-align: center;

    div {
      display: inline-block;

      button {
        text-align: center;
        color: $linkTextColor;
        background: transparent;
        div {
          display: inline-block;
          background: transparent;
          text-decoration: underline;
          &:hover {
            text-decoration: none;
          }
        }
        &:hover {
          color: $secondaryColor;
          background: transparent;
        }
      }
    }
  }

  &.newNotification ul li {
    padding-left: 45px;
  }
}
.mark_read {
  button:focus {
    opacity: 1 !important;
  }
}
