.backButtonOuter {
  padding-left: 30px;

  h1, .h1 {
    margin: 0;
    line-height: 34px;
    height: 30px;
    font-size: 18px;
    padding: 0;
    margin-bottom: 15px;
    margin-left: 5px;
  }

  .backButton2 {
    font-size: 22px;
    line-height: 24px;
    margin-top: 3px;
    text-decoration: none !important;
    margin-left: -30px;
  }
}