@import '../ui/comps/src/scssCore/_cssVarsColors';
@import '../ui/comps/src/scssCore/_cssVariables';

// COMMON STYLE

.usrLeftMenu {
	
	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li {
			list-style: none;


			a {
				display: block;
				text-decoration: none;
				background: $reverseColor;
				padding: 15px;
				color: $hintTextColor;
				line-height: 20px;
				font-family:$ff_opensansSemiBold;
				border: 1px solid $neutralColorBorderLighter;

				&:hover, &.active{
					border-left: 2px solid $primaryColor;
					background-color: $neutralColorBackground;
					color: $linkTextColor;
				}
			}
		}
		li + li{
			margin-top:-1px;
		}
	}

}

.tabularData-with-leftNumber{
	li{
		padding-left:40px;
		.num{
			margin-left:-40px;
			float:left;
			height:38px;
			line-height:38px;
			font-family:$ff_opensansSemiBold;
		}
	}
	li + li{
		padding-top:15px;
		border-top:1px solid $neutralColorBorderLighter;
	}
}

.tabularData-with-leftNumber + .tabularData-with-leftNumber{
	li{
		padding-top:15px;
		border-top:1px solid $neutralColorBorderLighter;
	}
}

.no-accordion-style{
	.card-header-false{
		padding:5px 0;
		background: none;
		margin:0 .5rem 1rem;
		border-bottom:1px solid $neutralColorBorderLight;
	}

}

.addRuleTable{
	margin:1rem .5rem;
}

.form-group-tableWrapper{
	.form-group{
		margin-bottom:0;
		@media screen and (max-width: $breakR_m) {
			padding:5px 0;
		}
	}
	@media screen and (max-width: $breakR_m) {
		.btnContainer{
			padding-top:10px;
		}
	}
}
.form-item {
	&.primaryCareInput {
		.form-control {
			width: 33%;

			@media screen and (max-width: $breakR_m) {
				width: 100%;
			}
		}
	}
}