@import "../../../scssCore/cssVarsColors";

.verificationContactIcon {
	position: absolute;
	right: 10px;
	top: 10px;
}

.success_icon {
	margin-top: -2px;
	color: $successColor;
}

.warning_icon {
	margin-top: -2px;
	color: $warningColor;
}