@import '../../ui/comps/src/scssCore/_cssVarsColors';
@import '../../ui/comps/src/scssCore/_cssVariables';

// COMMON STYLE

.documentListTable {
	.tableLayout {
		thead,
		tbody {
			th:last-child,
			td:last-child {
				width: 100px;
			}
			td:last-child {
				.sm-right-action-position {
					display: block;
					text-align: center;
				}
			}
			th:last-child {
				text-align: center;
			}
		}
	}

	@media screen and (max-width: $break_l) {
		td:nth-child(4),
		td:nth-child(5) {
			.xs-label {
				display: none !important;
			}
		}
	}
}

.documentListTable {
	.tableLayoutOuter.with-left-checkbox {
		.tableLayout {
			thead,
			tbody {
				th:last-child,
				td:last-child {
					width: 100px;
				}
				th:first-child,
				td:first-child {
					width: 60px !important;
					text-align: center;
				}
				td:last-child {
					.sm-right-action-position {
						display: block;
						text-align: center;
					}
				}
				th:last-child {
					text-align: center;
				}
			}
		}

		@media screen and (max-width: $break_l) {
			td:nth-child(4),
			td:nth-child(5) {
				.xs-label {
					display: none !important;
				}
			}
		}
	}
}

.breakAll {
	word-break: break-all;
}

.documentListTable {
	.td1 {
		width: 33%;
	}
	.td2 {
		width: 190px;
	}
}

.documentListTable,
.uploadDocumentTable {
	.typeIcon {
		width: 18px;
		height: 18px;
		border-radius: 50%;
		background: grey;
		color: $reverseColor;
		line-height: 18px;
		text-align: center;
		margin-right: 6px;
	}
	.typeIcon.icon-PDF-file {
		background: $color-widget_6;
	}
	.typeIcon.icon-PNG_image {
		background: $color-widget_1;
	}
	.typeIcon.icon-JPG_image {
		background: $color-widget_3;
	}

	.docTtl {
		display: inline-block;
	}
}

.folderSelectBtn {
	position: absolute;
	top: 0;
	right: 0;
	margin: 0;
	opacity: 1;
	font-size: 200px;
	direction: ltr;
	cursor: pointer;
	height: 100%;
	max-width: 140px;
	z-index: -9;
}

.bulkUpdate {
	text-align: right;
}
@media screen and (max-width: $breakR_xs) {
	.bulkUpdate {
		text-align: left;
	}
}
.hidden-md {
	display: none;
}
@media screen and (max-width: $breakR_m) {
	.hidden-sm {
		display: none;
	}
	.hidden-md {
		display: inline-block;
		margin-right: 8px;
	}
}
.progress {
	height: 3px !important;
	.progress-bar {
		background-color: $successColor;
	}
}
.icon-radiology-02.typeIcon {
	background: #454f5b !important;
}
.gifUploadImg {
	img {
		width: 70px;
	}
}
.bulkUpdate {
	.modal-content {
		text-align: left;
	}
}
.clearListDocModal {
	.modal-body {
		min-height: auto;
	}
}

/* DICOM DOWNLOAD ACTION STYLE */
.recordToolbarBtns {
	width: 220px;
	display: inline-block;
	vertical-align: middle;
}

.recordToolbarBtns button i {
	font-style: normal;
	width: 90%;
	overflow: hidden;
	height: 24px;
	display: inline-block;
}

.recordToolbarBtns button {
	outline: none !important;
}

.recordToolbarBtns .dropdown-menu a {
	word-break: break-all !important;
	width: 100%;
	white-space: normal !important;
	outline: none !important;
}

.showHideTrend .one {
	display: none;
}

.showHideTrend2 .one {
	display: inline;
}

.showHideTrend2 .two {
	display: none;
}

.recordToolbar .recordToolbarBtns .btn-default {
	width: 100%;
	text-align: left;
}

.recordToolbar .recordToolbarBtns .btn-default .caret {
	float: right;
	margin: 8px 0 0 0;
}

.recordToolbarBtns .dropdown-menu {
	width: 100%;
	padding: 0;
	margin: 0;
	border-radius: 0;
	font-size: 0.9em;
}

.recordToolbarBtns .dropdown-menu li + li {
	border-top: 1px solid $neutralColorBorderLight;
}

.recordToolbarBtns .dropdown-menu > li > a {
	padding: 5px 10px;
}

.dropdown-submenu a .caret {
	float: right;
	margin: 7px 0 0 0;
}

.dropdown-submenu .dropdown-menu {
	position: static;
	margin-top: 0;
	border: 0;
	box-shadow: none;
}

.dropdown-submenu .dropdown-menu li a {
	padding-left: 25px;
}

.dicomDownloadPopup {
	position: fixed;
	z-index: 99;
	background: $reverseColor;
	border: 1px solid $neutralColorBorderLight;
	border-radius: 3px;
	box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
	width: 400px;
	right: 20px;
	bottom: 60px;
	overflow: hidden;
}

.dicomDownloadPopup .popTitle {
	background: $primaryColor;
	color: $reverseColor;
	padding: 10px 15px;
}

.dicomDownloadPopup .popTitle button,
.popList .myIcon {
	margin: 0 3px !important;
}

.dicomDownloadPopup .popTitle h2 {
	margin: 0;
	font-size: 1.2em;
	line-height: 20px;
	color: $reverseColor;
}

.dicomDownloadPopup .popTitle p {
	margin: 0;
}

.popList {
	margin: 15px;
	font-size: 0.9em;
}

.popList ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.popList ul li {
	margin: 0;
	padding: 0;
}

.popList ul li + li {
	margin-top: 15px;
}

.popList ul li .spinner {
	display: inline-block;
	width: 18px;
	height: 18px;
	border-width: 2px !important;
	position: static;
}

.popList ul li .col-xs-9 {
	padding-top: 1px;
}

@media screen and (max-width: 767px) {
	.dicomDownloadPopup {
		width: 94%;
		right: 3%;
		bottom: -2px;
		border-radius: 5px 5px 0 0;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
	}
}

/* DICOM DOWNLOAD ACTION STYLE ENDS */

.myIcon {
	border: 0;
	padding: 0;
	background-image: url(../../assets/images/myIcons.png) !important;
	background-repeat: no-repeat;
	width: 30px;
	height: 30px;
	display: inline-block;
	text-decoration: none !important;
	border: 0;
	opacity: 0.7;
	vertical-align: middle;
	background-color: transparent !important;
}

.myIcon:hover {
	opacity: 1;
}

.myIconW {
	background-image: url(../../assets/images/myIconsW.png) !important;
	opacity: 1 !important;
}

.arrowDownSignBtn {
	background-position: -420px 0;
}

.arrowDownSignBtnS {
	width: 18px;
	height: 18px;
	background-position: -426px -6px;
}

.arrowUpSignBtn {
	background-position: -450px 0;
}

.arrowUpSignBtnS {
	width: 18px;
	height: 18px;
	background-position: -456px -6px;
}

.popList .mr_5 {
	margin-left: 5px;
	margin-right: 0;
}

.c_point,
.cPoint {
	cursor: pointer;
}

.pull-right.flip {
	float: right !important;
}

.setMargin {
	margin-top: -4px !important;
}

.downloadSpinnerPosition {
	position: absolute;
	right: 40px;
}

.crossSignBtn {
	background: url(../../assets/images/myIcons.png) no-repeat 0 0;
	width: 30px;
	height: 30px;
	display: inline-block;
	text-decoration: none !important;
	border: 0;
}

.DS_container .crossSignBtn,
.crossSignBtnS {
	width: 18px;
	height: 18px;
	background-position: -6px -6px;
}

.alert1 {
	position: relative;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 0.25rem;
	padding: 10px;
	padding-left: 20px;
	border: 1px solid $neutralColorBorder;
	border-radius: 0.25rem;
	@media screen and (max-width: $breakR_m) {
		padding-left: 40px;
	}
}
.alert1.alert-danger {
	color: $errorColor;
	background: $errorBackgroundColor;
	border-color: $errorColor;
}
.alert1 {
	@media screen and (max-width: $breakR_m) {
		.icon-info2 {
			margin-left: -30px;
		}
	}
}
.documentUpload {
	.width-150 {
		width: 200px;
	}
}
.topMargin15 {
	.alert {
		margin-top: 15px;
	}
}
.documentUpload {
	.bg-white {
		@media screen and (max-width: $breakR_m) {
			background-color: $neutralColorBackgroundLight !important;
		}
	}
}
.div_labelComp_shareWithoutPinLabel {
	label {
		background-color: $warningBackgroundColor;
		width: 100%;
		padding: 10px 15px !important;
		color: $warningForegroundColor;
		border: 1px solid $warningColor;
		border-radius: 2px;
		font-family: $ff_opensans;
	}
}
