// Created by Naved : 01Feb22 07:08

@import '_cssVarsColors';
@import '_cssVariables';

// COMMON STYLE

body[dir="rtl"] {

body, .App{
  text-align:right;
}

.dropdown-menu.reverse, .dropdown-menu.dropdown-menu-right {
  left: 0 !important;
  right: auto !important;
}

.align-right{
  text-align: left;
}

// BUTTONS

.btn, button{
  &.btnWithIconText {
    padding-right: 10px;
    padding-left: 15px;
  }
}

.btn-lg {
  &.btnWithIconText {
    padding-left: 20px;
    padding-right: 15px;
  }
}

.btnWithIconText.iconBefore .fIcon{
  margin-left: 5px;
  margin-right: 0;
}

.btn-group>.btn-group:not(:last-child)>.btn, .btn-group>.btn:not(:last-child):not(.dropdown-toggle){
  border-radius:$radius_4;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group>.btn-group:not(:first-child)>.btn, .btn-group>.btn:not(:first-child){
  border-radius:$radius_4;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn-group:not(:first-child), .btn-group>.btn:not(:first-child){
  margin-right:-1px;
  margin-left:0;
}

// END BUTTONS

// RADIO/CHECKBOX

.form-check-input {
  margin-right: 0;
  margin-left: 8px;
}

  .form-check-block{
    padding-left:0;
    padding-right:1.75rem;
    .form-check-input{
      margin:0 -1.75rem 0 0;
      float:right;
    }
  }

.custom-switch{
  padding-left: 0;
  padding-right: 2.25rem;
}

.custom-control-input{
  left:auto;
  right:0;
}

.custom-control.custom-switch .custom-control-label{
  margin-right: 8px;
  margin-left: 0;
}

.custom-control.custom-switch .custom-control-label::before{
  left: auto;
  right: -44px;
}

.custom-control.custom-switch .custom-control-label::after{
  left: auto;
  right: -45px;
}

.custom-control.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(-1.1rem);
}


// END RADIO/CHECKBOX


// INPUT

.input-group:not(.has-validation)>.custom-file:not(:last-child) .custom-file-label::after, .input-group:not(.has-validation)>.custom-select:not(:last-child), .input-group:not(.has-validation)>.form-control:not(:last-child){
  border-radius:$radius_4;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group>.input-group-append>.btn, .input-group>.input-group-append>.input-group-text, .input-group>.input-group-prepend:first-child>.btn:not(:first-child), .input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child), .input-group>.input-group-prepend:not(:first-child)>.btn, .input-group>.input-group-prepend:not(:first-child)>.input-group-text{
  border-radius:$radius_4;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group-append{
  margin-right: -1px;
  margin-left: 0;
}

.input-group.withSpace .input-group-append .btn{
  margin-left: 0;
  margin-right: 10px;
}

// END INPUT

// NOTIFICATION

.alert.withClose{
  padding-left: 45px;
  padding-right: 15px;

  button.close{
    left: 10px;
    right: auto;
  }
}

.alert.withLeftIcon{
  padding-right: 45px;
  .leftIcon{
    right:10px;
    left: auto;
  }
}

// END NOTIFICATION

// TAGS

.filterTags .tag{
  padding-right: 10px;
  padding-left: 35px;
  &.fixed{
    padding:$padding_5_10;
  }
  .close{
    float: left;
    margin-left: -25px;
  }
}

.fileUploaderTags .tag{
  padding: 5px 30px 5px 45px;
  .fileIcon{
    float: right;
    margin-left:0;
    margin-right: -25px;
  }
  .close{
    float: left;
    margin: -5px 0 -5px -45px;
    border-width:0 1px 0 0;
  }
}

// END TAGS

// PAGINATION

.np_links{
  .btn-prev{
    .fIcon{
      transform: rotate(90deg);
      margin-top:-1px;
    }
  }
  .btn-next{
    .fIcon{
      transform: rotate(180deg);
      margin-top:-1px;
    }
  }
}

// END PAGINATION


// TABLE

  .tableLayoutOuter:not(.noLeftRightBorder){
    .tableLayout, .tableLayoutSM, .tableLayoutXS {
      table {

        td, th {
          border-width: 0 1px 1px 0;
        }

        tr td:first-child, tr td.first, tr th:first-child, tr th.first,
        tr td:first-child[style*="display: none"] + td, tr td:first-child[style*="display:none"] + td,
        tr td.first[style*="display: none"] + td, tr td.first[style*="display:none"] + td,
        tr th:first-child[style*="display: none"] + th, tr th:first-child[style*="display:none"] + th,
        tr th.first[style*="display: none"] + th, tr th.first[style*="display:none"] + th {
          border-right-width: 0 !important;
          border-left-width: 1px !important;
          @media screen and (max-width: $breakR_m) {
            border-left-width: 0 !important;
          }
        }

        tr td:last-child, tr td.last, tr th:last-child, tr th.last {
          border-right-width: 1px !important;
          border-left-width: 0 !important;
          @media screen and (max-width: $breakR_m) {
            border-right-width: 0 !important;
          }
        }

      }
    }
  }




@media screen and (max-width: $breakR_m) {

  .tableLayoutOuter{
    &.with-right-action{
      .tableLayout > table > tbody > tr > td {
        padding-left: 60px !important;
        padding-right: 15px !important;
      }
    }

    &.with-left-checkbox{
      .tableLayout > table > tbody > tr > td{
        padding-right: 45px !important;
      }
    }

    &.with-left-checkbox{
      .tableLayout table td.checkbox-cell .form-check,
      .tableLayout table th.checkbox-cell .form-check,
      .tableLayoutSM table td.checkbox-cell .form-check,
      .tableLayoutSM table th.checkbox-cell .form-check,
      .tableLayoutXS table td.checkbox-cell .form-check,
      .tableLayoutXS table th.checkbox-cell .form-check{
        left:auto;
        right:15px;
      }
    }

  }

  .sm-right-action .sm-right-action-position{
    right: auto;
    left: 15px;
  }

  .customTable .customTable-row .customTable-cell{
    .sm-right-action-position{
      left:15px;
      right:auto;
    }
    &.checkbox-cell .form-check{
      right: 15px;
      left: auto;
    }

  }
  .customTable.with-right-action .customTable-body .customTable-row{
    padding-right: 15px;
    padding-left: 60px;
  }

  .customTable.with-left-checkbox .customTable-body .customTable-row{
    padding-right: 45px;
  }

}

.customTable .customTable-row .customTable-cell{
  border-width:0 0 0 1px;
}

.tableLayout td .text-label, .tableLayoutSM td .text-label, .tableLayoutXS td .text-label{
  margin-left: 3px;
  margin-right: 0;
}

// END TABLE

// ACCORDION

.text-left{
  text-align:right !important;
}

.accordion-outer {
  .accordion {
    .card {
      .card-header h2 {
        .btn {
          padding-left:45px;
          padding-right:0;

          &::after{
            left: 10px;
            right: auto;
            transform: rotate(90deg);
          }
          &.collapsed::after{
            transform: rotate(-90deg);
          }

        }

      }
    }
  }
}


// END ACCORDION


// DEMOGRAPHICS

.demographics-patient{
  .avatar.float-left{
    float:right !important;
  }
  &.widthImg48 {
    padding-right: 60px;
    padding-left: 0;

    .widthImg48 {
      margin-right: -60px;
      margin-left: 0;
    }
  }
}

// END DEMOGRAPHICS

// CUSTOM TOOLTIP

.tabularTooltip, .textTooltip{
  .tabularTooltip-box, .textTooltip-box{
    right:0;
    left:auto;
  }
  .arrowUp{
    float:right;
  }
  &.reverse{
    .tabularTooltip-box, .textTooltip-box{
      left:0;
      right:auto;
    }
    .arrowUp{
      float:left;
    }
  }

}

// END CUSTOM TOOLTIP

// PATIENT BANNER

.patientBanner {
  .patInfo{
    padding:0 74px 0 0;

    .avatar{
      float:right;
      margin-right:-74px;
      margin-left:0;
    }

  }
}

.patientBannerNav{
  padding:10px 15px 10px 250px;
  .patBannerNav{
    float:right;
  }
  .patBannerAction{
    float: left;
    margin-right:0;
    margin-left: -235px;
  }
}

@media screen and (max-width: $breakR_s) {

  .tabularTooltip, .textTooltip{
    &.reverse.not-small{
      .tabularTooltip-box, .textTooltip-box{
        right: 0;
        left: auto;
      }
      .arrowUp {
        float: right;
      }
    }
  }
}


// END PATIENT BANNER

// PAGE TITLE

.page-title{
  padding-right:40px;
  padding-left:0;

  .back-button{
      right:0;
      left:auto;
    a{
      .fIcon{
        transform: rotate(90deg);
        margin:1px -1px 0 0px
      }
    }
  }
}

  // Dynamic password hint checklist
  .dynamicPassword-checklist {
    .fIcon {
      right: 0 !important;
      margin-right: 0;
    }
    li {
      padding-left: 0px;
      padding-right: 20px;
    }
  }

  // END PAGE TITLE

  // Login Page RTL

  .has-hintIcon {
    .fIcon {
      left: 7px;
      right: auto;
    }
  }


  // Login Page RTL ends

}
