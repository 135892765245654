@import '../../../../../ui/comps/src/scssCore/_cssVarsColors';
@import '../../../../../ui/comps/src/scssCore/_cssVariables.scss';

// ALERT/NOTIFICATION


.alert {
  padding: $padding_10_15 !important;
  border: 1px solid $neutralColorBorder;
  border-radius: $radius_4;
  background: $neutralColorBackgroundLight;

  .alert-link{
    color: inherit;
    font-weight:400;
    text-decoration: underline;
    &:hover{
      text-decoration: none;
    }
  }

  &.withClose {
    padding-right: 45px;

    button {
      box-shadow: none;
      width: 24px;
      height: 24px;
      background: none;
      border:none;
      opacity:1;

      &.close {
        position: absolute;
        right:10px;
        top:8px;
      }

      .iconClose {
        font-size: 1rem;
        font-weight:600;
        line-height: 1.5rem;
        cursor: pointer;
        display:block;
      }

    }
  }

  &.withLeftIcon {
    padding-left: 45px;

    .leftIcon {
      font-size: 1.5rem;
      line-height: 1.5rem;
      position: absolute;
      left:10px;
      top:8px;
    }
  }

  &.alert-primary, &.alert-primary .iconClose{
    color: $primaryColor;
    background: $reverseColor;
    border-color: $primaryColor;

    .noBtnStyle{
      color: $errorColor;
      font-family: $ff_opensans;
    }
  }

  &.alert-secondary, &.alert-secondary .iconClose, &.alert-default, &.alert-default .iconClose {
    color: $bodyTextColor;
    background: $neutralColorBackground;
    border-color: $neutralColorBackground;

    .noBtnStyle{
      color: $errorColor;
      font-family: $ff_opensans;
    }
  }

  &.alert-success, &.alert-success .iconClose {
    color: $successForegroundColor;
    background: $successBackgroundColor;
    border-color: $successBackgroundColor;

    .noBtnStyle{
      color: $errorColor;
      font-family: $ff_opensans;
    }
  }

  &.alert-danger, &.alert-danger .iconClose, &.alert-error, &.alert-error .iconClose {
    color: $errorForegroundColor;
    background: $errorBackgroundColor;
    border-color: $errorBackgroundColor;

    .noBtnStyle{
      color: $errorColor;
      font-family: $ff_opensans;
    }
  }

  &.alert-warning, &.alert-warning .iconClose {
    color: $warningForegroundColor;
    background: $warningBackgroundColor;
    border-color: $warningBackgroundColor;

    .noBtnStyle{
      color: $errorColor;
      font-family: $ff_opensans;
    }
  }

  &.alert-info, &.alert-info .iconClose, &.alert-information, &.alert-information .iconClose {
    color: $informationForegroundColor;
    background: $informationBackgroundColor;
    border-color: $informationBackgroundColor;

    .noBtnStyle{
      color: $errorColor;
      font-family: $ff_opensans;
    }
  }

}

// END ALERT/NOTIFICATION