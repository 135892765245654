@import '../scssCore/_cssVarsColors';
@import '../scssCore/_cssVariables';

.btn-nostyles {
	box-shadow: none;
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.btn-nostyles:hover, .btn-nostyles:active, .btn-nostyles:focus {
	box-shadow: none;
	background: none;
}

fieldset {
	min-width: 0;
	margin: 0;
}

fieldset, legend {
	padding: 0;
	border: 0;
}

.rel {
	position: relative;
}

 .has-hintIcon .fIcon {
	position: absolute;
	right: 7px;
	top: 7px;
	z-index: 1;
	display: none;
	cursor: pointer;
}

.has-hintIcon .dynamicPassword-checklist .fIcon {
	top: 3px !important;
}

.has-hintIcon.verified .fIcon.iconVerified {
	display: block;
	color: $successColor;
	cursor: default;
}

.has-hintIcon.delete .fIcon.iconDelete {
	display: block;
	color: $hintTextColor;
}

.has-hintIcon.edit .fIcon.iconEdit {
	display: block;
	color: $hintTextColor;
}

.removeUnderline {
	text-decoration: none;
}

.iconSize1 {
	font-size: 22px;
	line-height: 18px;
	height: 20px;
}
.dottedBox {
	margin-top: 1.5rem;
	margin-bottom: 2.5rem;
	background: $neutralColorBackground;
	border: 1px dashed $neutralColorBorderLight;
	padding: 10px 30px;
	font-size:2rem;
	line-height:3rem;

	.fIcon{
		position: absolute;
		right: -20px;
		bottom: -23px;
		font-size: 2.5rem;
		color:$successColor;
	}

	@media screen and (max-width: $break_s) {
		font-size:1.5rem;
		line-height:2.25rem;
	}
	display: inline-block;
	position: relative;
}
