// Created by Naved : 01Feb22 07:08

@import '_cssVarsColors';
@import '_cssVariables';

// COMMON STYLE

.separator {
  border-bottom: 1px solid $neutralColorBorderLighter;
  font-size: $fs_xl;
  line-height: $lh_xl;
  font-family: $ff_opensansBold;
  color: $neutralColorBorder;
  margin-bottom: $margin_bottom_15;
  padding-bottom: 5px;
  text-transform: uppercase;
  margin-top:10px;
}

.separator2 {
  font-size: $fs_xl;
  line-height: $lh_xl;
  color: $neutralColorBorder;
  margin-bottom: 10px;
}

hr {
  margin-top: 15px;
  margin-bottom: 15px;
  border-color: $neutralColorBorderLighter;
}


.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none;
}

.disabled{
  cursor: not-allowed;
  opacity:.65;
  pointer-events: none;
}

.btn-link.noUnderline, .noUnderline {
  text-decoration: none !important;
}

html, body {
  min-height:100%;
  width:100%;
}

body {
  padding:0;
  margin: 0;
  background: $neutralColorBackgroundLight;
  color: $bodyTextColor;
  font-family: $ff_opensans;
  font-size: $fs_m;
  line-height: $lh_m;
  min-height: calc(100vh);
  min-width: 320px;
}

.cardOuter {
  background:$reverseColor;
  // border: 1px solid $neutralColorBorderLighter;
  padding:$padding_15;
  margin-top: $margin_top_15;
  margin-left: $margin_left_15;
  margin-right: $margin_right_15;
  position: relative;
  box-shadow: $shadow_box;

  @media screen and (max-width: $breakR_m) {
    background:none;
    padding:0;
    box-shadow: none;
  }

}
@media screen and (max-width: $breakR_m) {
  .m-card{
    background:$reverseColor;
    padding:$padding_15;
    box-shadow: $shadow_box;
    &.noBorder{
      border:0 !important;
    }
  }
}

.minHeight {
  min-height: calc(100vh - 120px);
}

.cardOuter-blank {
  margin-top: $margin_top_15;
  margin-left: $margin_left_15;
  margin-right: $margin_right_15;
  position: relative;
}

.height-auto{
  height:auto;
}

.min-h-100{
  min-height:100%;
}

p{
  margin:0;
  padding:0;
}

h1, h2, h3, h4, h5, h6{
  margin:0;
}

.leftFilter-divider{
  border-color:$neutralColorBorderLighter;
  margin-bottom:15px;
}

.no-wrap{
  white-space: nowrap;
}

.preWrap {
  white-space: pre-wrap;
}

.no-wrap-ellipsis{
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

// END COMMON STYLE


// ANIMATION
.transition_2{
  transition: all linear .2s;
}
// END ANIMATION


// WIDTH
.width-10p{
  width:10%;
}
.width-15p{
  width:15%;
}
.width-20p{
  width:20%;
}
.width-25p{
  width:25%;
}
.width-30p{
  width:30%;
}
.width-40p{
  width:40%;
}
.width-50p{
  width:50%;
}
.width-60p{
  width:60%;
}
.width-70p{
  width:70%;
}
.width-80p{
  width:80%;
}
.width-90p{
  width:90%;
}
.width-100p{
  width:100%;
}

.width-150{
  width:150px;
}

.width-120{
  width:120px;
}

// END WIDTH

// ROW

.row.row-small{
  margin-left:-5px;
  margin-right:-5px;
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto{
    padding-left:5px;
    padding-right:5px;
  }
}

// END ROW

// CURSOR

.cursor-pointer{
  cursor: pointer;
}

// END CURSOR

// FONT-SIZE


.body-text-size{
  font-size: $fs_m;
  line-height: $lh_m;
}

.body-text-large{
  font-size: $fs_xl;
  line-height: $lh_xl;
}

.body-text-larger{
  font-size: $fs_xxl;
  line-height: $lh_xxl;
}


h5, .h5, h6, .h6, label, .label {
  font-size: $fs_l;
  line-height: $lh_l;
}

h3, .h3, h4, .h4 {
  font-size: $fs_xl;
  line-height: $lh_xl;
}

h2, .h2 {
  font-size: $fs_xxl;
  line-height: $lh_xxl;
}

h1, .h1 {
  font-size: $fs_xxxl;
  line-height: $lh_xxxl;
}

.big {
  font-size: $fs_big;
  line-height: $lh_big;
}

.bigger {
  font-size: $fs_bigger;
  line-height: $lh_bigger;
}

.biggest {
  font-size: $fs_biggest;
  line-height: $lh_biggest;
}

// END FONT-SIZE


// FONT-FAMILY

.fs-normal{
  font-family: $ff_opensans;
}

.fs-semiBold, .semiBold {
  font-family: $ff_opensansSemiBold;
}

.fs-bold, .bold {
  font-family:$ff_opensansBold;
}

.fs-italic{
  font-family:$ff_opensansItalic;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: $ff_opensansSemiBold;
}

label, .label {
  font-family: $ff_opensansSemiBold;
}

.text-demographic-label {
  font-family: $ff_opensansSemiBold;
}

b, strong, .strong, .text-bold{
  font-family: $ff_opensansSemiBold;
  font-weight: normal;
}

i, em{
  font-style: italic;
}

// END FONT-FAMILY


// TEXT COLOR

.biggest, .bigger, .big, h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: $titleColor;
}

label, .label {
  color: $bodyTextColor;
}

.text-default, .text-body {
  color: $bodyTextColor;
}

.text-primary{
  color:$primaryColor !important;
}

.text-hint, .text-disabled, .text-muted {
  color: $hintTextColor;
}

.text-demographic-label, .text-label {
  font-family:$ff_opensansSemiBold;
  font-style:normal;
}

.text-title{
  color: $titleColor;
}

.text-info{
  color:$informationColor !important;
}

.text-info-background{
  color:$informationBackgroundColor;
}

.text-success{
  color:$successColor !important;
}

.text-success-background{
  color:$successBackgroundColor !important;
}

.text-warning, .text-alert{
  color:$warningColor !important;
}

.text-warning-background, .text-alert-background{
  color:$warningBackgroundColor;
}

.text-danger, .text-error, .red{
  color:$errorColor !important;
  margin-top: 5px;
}
.text-danger-background, .text-error-background{
  color:$errorBackgroundColor;
}

// END TEXT COLOR


// LINKS

a.underline-reverse, button.underline-reverse{
  text-decoration: none;
  &:hover, &:focus{
    text-decoration: underline;
  }
}

.link-with-topIcon{
  text-align: center;
  display:inline-block;
  text-decoration: none;
  .fIcon{
    font-size:1.5rem;
    width:2rem;
    height:2rem;
    line-height:2rem;
    display:block;
    margin:0 auto;
  }

}

// END LINKS

// DISPLAY

.dib, .display-inline-block {
  display: inline-block;
}

.db, .display-block {
  display: block;
}

.di, .display-inline{
  display:inline;
}

.dn, .display-none{
  display: none;
}

.d-flex{
  display:flex;
}

.d-flex-center{
  display:flex;
  padding:3rem 2rem;
  justify-content: center;
  align-items: center;
}

// END DISPLAY


// ALIGNMENT

.align-left{
  text-align: left;
}

.align-right{
  text-align: right;
}

.align-center{
  text-align: center;
}

.align-justify{
  text-align:justify;
}

.vam {
  vertical-align: middle;
}

.vat {
  vertical-align: top;
}

.vab {
  vertical-align: bottom;
}

// END ALIGNMENT



// BADGES

.badge{
  font-size: $fs_m;
  font-weight:$fw_400;
  vertical-align: text-bottom;
  color: $bodyTextColor;
  background: $neutralColorBackground;
  border: 1px solid $bodyTextColor;
  text-align:center;

  &.badge-rounded {
    height: 22px;
    min-width: 22px;
    padding: 2px;
    font-size: $fs_s;
    line-height: $lh_s;
    border-radius: $radius_100;
    font-family: $ff_opensansSemiBold;
  }

  &.badge-primary{
    color: $primaryColor;
    background: $reverseColor;
    border-color: $primaryColor;
  }

  &.badge-secondary{
    color: $bodyTextColor;
    background: $neutralColorBackground;
    border-color: $bodyTextColor;
  }

  &.badge-success{
    color: $successForegroundColor;
    background: $successBackgroundColor;
    border-color: $successForegroundColor;
  }

  &.badge-danger{
    color: $errorForegroundColor;
    background: $errorBackgroundColor;
    border-color: $errorForegroundColor;
  }

  &.badge-warning{
    color: $warningForegroundColor;
    background: $warningBackgroundColor;
    border-color: $warningForegroundColor;
  }

  &.badge-info{
    color: $informationForegroundColor;
    background: $informationBackgroundColor;
    border-color: $informationForegroundColor;
  }

  &.reverse {
    color: $reverseTextColor;

    &.badge-primary{
      background: $primaryColor;
    }

    &.badge-secondary{
      background: $bodyTextColor;
    }

    &.badge-success{
      background: $successColor;
    }

    &.badge-danger{
      background: $errorColor;
    }

    &.badge-warning{
      background: $warningColor;
    }

    &.badge-info{
      background: $informationColor;
    }
  }

  &.badge-status {
    border: none;
    // box-shadow: $shadow_button;
    padding:0 6px;
    line-height:20px;
  }

}


// END BADGES

// TAGS

.btn-slot-time {
  margin: -5px;

  label {
    display: inline-block;
    margin: 5px;

    input.noshow {
      display: block;
      position: absolute;
      z-index: -999;
      opacity: 0;

      + span {
        display: inline-block;
        padding: 6px 10px;;
        border: 1px solid $primaryColor;
        height: 32px;
        color: $primaryColor;
        border-radius: $radius_4;
        cursor: pointer;
        font-weight: $fw_400;
        line-height:$lh_m;
      }

      &.disabled {
        + span {
          background: $neutralColorBackground;
          color: $bodyTextColor;
          cursor: not-allowed;
          border: 0;
        }
      }

      &:checked{
        + span {
          background: $primaryColor;
          color: $reverseTextColor;
          border: 0;
        }
      }

    }

  }

}

// END TAGS


// AVATARS

.avatar {
  background: $avatarColor;
  color: $linkTextColor;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
  font-weight: normal;
  border-radius: $radius_100;
  border:1px solid $linkTextColor;
  overflow: hidden;

  &.grey{
    background: $neutralColorBorderLighter;
    color: $bodyTextColor;
    border:1px solid $bodyTextColor;
  }

  .img{
    width:100%;
    height:100%;
    img{
      display:block;
      width:100%;
      height:100%;
      margin:auto;
    }
  }

  &.smaller{
    font-size: $fs_m;
    height: 32px;
    width: 32px;
    line-height: 32px;

    .fIcon {
      font-size: 20px;
      line-height: 32px;
    }
  }

  &.small{
    font-size: 21px;
    height: 48px;
    width: 48px;
    line-height: 48px;

    .fIcon {
      font-size: 30px;
      line-height: 48px;
    }
  }

  &.medium{
    font-size: 28px;
    height: 64px;
    width: 64px;
    line-height: 64px;

    .fIcon {
      font-size: 40px;
      line-height: 64px;
    }
  }

  &.large{
    font-size: 36px;
    height: 100px;
    width: 100px;
    line-height: 80px;
    padding: 8px;

    .fIcon {
      font-size: 50px;
      line-height: 80px;
    }
  }
}


// END AVATARS

// BUTTONS

.btn, button {
  font-family: $ff_opensansSemiBold;
  border-radius: 2px;
  // box-shadow: $shadow_button;
  height: 38px;
  padding: 6px 12px;
  font-size: $fs_m;
  line-height: $lh_m;
  white-space: nowrap;


  .fIcon{
    //font-weight:600;
  }

  &:hover, &:focus, &.focus {
    box-shadow: $shadow_button;
  }

  &.btnWithIconText {
    padding-left: 10px;
  }

  &.btnWithIconOnly {
    min-width:38px;
    text-align:center;
    padding: 8px !important;

    .fIcon{
      font-size:24px;
      line-height:20px;
    }
    &.btn-sm{
      .fIcon{
        font-size:20px;
        line-height:16px;
        margin-top:-1px;
      }
    }

  }

}

.btn-group {
  a {
    text-decoration: none;
  }
}

.iconBtnSize, .btnWithIcon .fIcon, .btnWithIconText .fIcon, .editable-buttons .glyphicon {
  font-size: $fs_xxxl;
  height: 23px;
  line-height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.btnWithIconText.iconBefore .fIcon {
  margin-right: 5px;
}

.btn-lg {
  height: 48px;
  font-size: $fs_xl;
  line-height: $lh_xl;
  padding: 12px 20px;

  &.btnWithIconText {
    padding-left: 15px;

    .fIcon {
      font-size: 28px;
    }
  }
}


.btn-sm {
  height: 32px;
  font-size: $fs_m;
  line-height: $lh_m;
  padding: 5px 10px;

  &.btnWithIconText {
    padding-left: 10px;

    .fIcon {
      font-size: 16px;
      margin-right:5px;
    }
  }

}


.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle, .btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  background: $secondaryColorDark;
  border-color: $secondaryColor;
  color: $primaryButtonColor;
}

.btn-primary {
  background: $primaryColor;
  border-color: $primaryColor;
  color: $primaryButtonColor;

  &:hover, &:focus, &.focus, &:active {
    background: $secondaryColor;
    border-color: $secondaryColor;
    color: $primaryButtonColor;
  }

  &.disabled, &:disabled {
    opacity:0.65;
    pointer-events: none;
    background: $primaryColor;
    border-color: $primaryColor;
    color: $primaryButtonColor;
  }
}


.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle, .btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus {
  background: $tertiaryColor;;
  border-color: $secondaryButtonColor;
  color: $textOnTertiary;
}

.btn-secondary {
  background: $tertiaryColor;
  border-color: $textOnTertiary;
  color: $textOnTertiary;

  &:hover, &:focus, &.focus, &:active {
    background: $tertiaryColorDark;
    color: $textOnTertiary;
  }

  &.disabled, &:disabled {
    opacity:0.65;
    pointer-events: none;
    background: $tertiaryColor;;
    border-color: $secondaryButtonColor;
    color: $textOnTertiary;
  }
}


.btnContainer {
  margin: 0 -5px 0 -5px; // top/bottom margin getting issue

  .btn-space, .btn, .btn-group {
    margin: 0 5px;
    &.btn-block{
      width:calc(100% - 10px);
    }
  }

  .btn-group{
    .btn{
      margin-left:0 !important;
      margin-right:0 !important;
    }
  }

}


.iconBtnOnly{
  .iconBtn{
    color:$primaryColor;
    height:20px;
    line-height:20px;
    border:0;
    background: none;
    box-shadow:none;
    padding:0;
    text-decoration:none !important;
    display:inline-block;
    vertical-align: top;
    &.p5{
      padding:5px;
      height:30px;
    }
    .fIcon{
      font-size:20px;
      height:20px;
      line-height:20px;
      display:block;
    }
  }
}


.actionBtn{
  &.btn-sm{
    width:32px;
    height:28px;
    padding:3px 5px;
  }
  .fIcon{
    font-size:20px;
    height:20px;
    line-height:20px;
    display:block;
  }
  &.insideTableCell{
    margin-top:-4px;
    margin-bottom:-4px;
    @media screen and (max-width: $breakR_m) {
      margin-top:0px;
      margin-bottom:0px;
    }
  }
}

.btn.btn-circle{
  width:38px;
  height:38px;
  text-align:center;
  padding:0 !important;
  border-radius:100% !important;
  min-width:0;

  .fIcon{
    font-size:1.5rem;
    line-height:36px !important;
    height:36px;
    display:block;
    margin:auto;
  }

  &.btn-sm{
    width:28px;
    height:28px;
    box-shadow:none;
    padding:0;

    .fIcon{
      font-size:1.125rem;
      line-height:26px !important;
      height:26px;
    }
  }
}





// END BUTTONS


// LINKS

a, .link-regular, .btn-link {
  font-size: $fs_m;
  line-height: $lh_m;
  text-decoration: underline;
  color: $linkTextColor;
  &.link-regular{
    font-family: $ff_opensans !important;
    font-weight:normal;
  }

  &:hover {
    color: $linkTextColor;
    text-decoration: none;
  }
  &.noPadding{
    padding:0;
    height:auto !important;
  }
  &.text-regular{
    font-family: $ff_opensans !important;
    font-weight:normal;
  }
}

.btn-link, .btn-link:focus, .btn-link:visited, .btn-link:active, .btn-link.active {
  border-radius: 0;
  padding: 0 !important;
  box-shadow: none !important;
  color: $linkTextColor;
  margin-bottom: 0;
}


// END LINKS


// DROPDOWN

@keyframes example {
  from {transform: translateY(1rem);}
  to {background-color: yellow;}
}

.dropdown-menu{
  box-shadow:$shadow_dropdown;
  border-radius:$radius_4;
  padding:0;
  top:100%;

  .dropdown-item{
    padding:$padding_10_15;
    font-size:$fs_m;
    text-decoration: none;
    font-family: $ff_opensans !important;
    box-shadow:none !important;
    &:first-child{
      border-radius:$radius_4t;
    }
    &:last-child{
      border-radius:$radius_4b;
    }
    &.active, &:active{
      background-color: $primaryColor;
    }
  }
  &.condensed{
    min-width:8rem;
    .dropdown-item{
      padding:7px 10px;
    }
  }
}

.dropdown-menu {
  &.reverse, &.dropdown-menu-right{

  }
}

/*
body[dir="rtl"] {
  .dropdown-menu {
    right:0;
    left:auto;
    &.reverse, &.dropdown-menu-right{
      left: 0 !important;
      right:auto !important;
    }
  }
}*/


.right-action-text-smIcon{
  text-align:right;
  width:120px;
  flex-shrink: 0;
  @media screen and (max-width: $breakR_m) {

    width:32px;
    &.width-64{
      width:64px;
      padding-top:0 !important;
    }
  }
}

@media screen and (max-width: $breakR_m) {
  .sm-btn, .sm-btn:active, .sm-btn:focus{

    width: 32px !important;
    height: 28px !important;
    padding: 3px 5px !important;
    text-decoration: none !important;
    background: $tertiaryColor;
    color: $secondaryButtonColor;
    font-family: "OpenSansSemiBold";
    border-radius: $radius_4 !important;
    box-shadow:$shadow_button !important;
    display:inline-block;
    border:1px solid $neutralColorBorder;

    &:hover, &:focus{
      background: $tertiaryColorDark;
      color: $secondaryButtonColor;
    }

    .dd-text{
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0,0,0,0);
      white-space: nowrap;
      border: 0;
    }

    .fIcon{
      font-size: 20px !important;
      height: 20px !important;
      line-height: 20px !important;
      width: 20px !important;
      display: block;
    }
  }
}
@media screen and (max-width: $breakR_s) {
  .s-btn, .sm-btn:active, .sm-btn:focus{
    width: 32px !important;
    height: 28px !important;
    padding: 3px 5px !important;
    text-decoration: none !important;
    background: $tertiaryColor;
    color: $secondaryButtonColor;
    font-family: "OpenSansSemiBold";
    border-radius: $radius_4 !important;
    box-shadow:$shadow_button !important;
    display:inline-block;
    border:1px solid $neutralColorBorder;

    &:hover, &:focus{
      background: $tertiaryColorDark;
      color: $secondaryButtonColor;
    }

    .dd-text{
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0,0,0,0);
      white-space: nowrap;
      border: 0;
    }

    .fIcon{
      font-size: 20px !important;
      height: 20px !important;
      line-height: 20px !important;
      width: 20px !important;
      display: block;
    }

  }
}

.dropdown{
  .dropdown-toggle{
    .icon-arrow_down1{
      transition:all linear .2s;
    }
  }
  &.show{
    .dropdown-toggle{
      .icon-arrow_down1{
        transform:rotate(-180deg);
      }
    }
  }
}


// END DROPDOWN


// CHECKBOX/RADIO/SWITCH

input[type=checkbox], input[type=radio] {
  cursor: pointer;
  border: 2px;
  height: 20px;
  width: 20px;
  accent-color:$primaryColor;
}

input[type=radio]:disabled, input[type=radio]:disabled + label, input[type=checkbox]:disabled, input[type=checkbox]:disabled + label {
  cursor: default;
}
.form-check-inline-outer{
  margin:0px -10px;
}

.form-item {
  &.w-100 {
    .form-check-inline-outer{
      margin: 5px -10px
    }
  }
}

.form-check-inline-label {
  margin:5px 10px;
}

.form-item > .form-group > div > span > label.form-check-inline-label {
  margin: 0;
}

.form-check-inline {
  margin:5px 10px;
}

.form-check-input {
  margin-right: 8px;
  border-radius: $radius_4;
}

.form-check-label {
  font-size: $fs-m;
  line-height: $lh_m;
  font-family: $ff_opensans;
  cursor: pointer;
}

.form-check-block{
  padding-left:1.75rem;
  .form-check-input{
    margin:0 0 0 -1.75rem;
    position: static !important;
    float:left;
  }
}



.custom-control {

  &.custom-switch {

    .custom-control-label {
      margin-left: 8px;
      font-family: $ff_opensans;

      &::before {
        left: -44px;
        width: 35px;
        background: $neutralColorBorder;
        border: none;
        height: 12px;
        top: 4px;
      }

      &::after {
        left: -45px;
        top: 0px;
        width: 20px;
        height: 20px;
        background-color: $reverseColor;
        border-radius: 100%;
        box-shadow: $shadow_modal;
      }
    }

    .custom-control-input:checked ~ .custom-control-label::after {
      background-color: $primaryColor;
      transform: translateX(1.1rem);
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      background-color: $primaryColor;
      opacity: 0.5;
    }

    .custom-control-input[disabled] ~  .custom-control-label::after{
      background-color: $neutralColorBorderLighter;
    }

    .custom-control-input[disabled] ~  .custom-control-label::before{
      background-color: $neutralColorBorder;
    }

  }

}

// END CHECKBOX/RADIO/SWITCH


// FORMS

.mandatory {
  color: $errorColor;
}
.form-group {

  label {

    &.falseLabel{
      min-height:20px;
      display:block;
      @media screen and (max-width: $breakR_s) {
        display:none;
      }
    }
  }

  .form-check{
    label {
      margin-bottom: 0;
      vertical-align: top;
    }
  }

  .input-group {

    .input-group-append {

      .btn {
        min-height: 38px;
        min-width: 38px;
      }

    }

  }

}
.form-control {
  height: 38px;
  padding: 8px 14px;
  font-size: $fs_m;
  line-height: $lh_m;
  color: #202020;
  border-color: $neutralColorBorder;

  &:focus {
    border-color: $primaryColor;
    box-shadow: 0 0 0 2px rgb(63 0 127 / 15%);
  }

  &.readonly, &.disabled  {
    background: $neutralColorBackground;
    pointer-events: none;
  }

  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $hintTextColor;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $hintTextColor;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: $hintTextColor;
  }

  &.search{
    padding-left:40px;
    & + .fIcon{
      left:10px;
      top:8px;
      position: absolute;
      color:$hintTextColor;
      font-size:20px;
      line-height:22px;
    }
  }

}

// multi-select

.css-1s2u09g-control{
  border-color:$neutralColorBorder !important;
}

.css-1okebmr-indicatorSeparator{
  background-color:$neutralColorBorder !important;
  margin-top:0 !important;
  margin-bottom:0 !important;
  display:none !important;
}

.css-tlfecz-indicatorContainer{
  color:$bodyTextColor !important;
  /*padding: 8px 11px !important;*/
}

.css-1pahdxg-control{
  border-color: $neutralColorBorder !important;
  box-shadow: 0 0 0 2px $outlineColor !important;
}

.css-6j8wv5-Input input:focus{
  outline: 0 !important;
}

.css-1rhbuit-multiValue{
  background: $neutralColorBackground !important;

  svg{
    width:18px !important; height:18px !important;
    color:$bodyTextColor;
  }

  .css-xb97g8:hover {
    background-color: $errorBackgroundColor !important;
    color: $errorColor !important;
  }
}
.css-12jo7m5{
  font-size:$fs_m !important;
}

// end multi-select

.form-text {
  font-size: $fs-m;
  color: $hintTextColor;
  margin-top: 5px;

  &.error {
    color: $errorColor;
  }

  &.success {
    color: $successColor;
  }
}

.has-error .form-control, .has-error .form-control:focus, .form-control.has-error, .form-control.has-error:focus {
  border-color: $errorColor;
}

.has-error{
  .form-text{
    color: $errorColor;
  }
}

.has-success .form-control, .has-success .form-control:focus, .form-control.has-success, .form-control.has-success:focus {
  border-color: $successColor;
}

.has-success{
  .form-text{
    color: $successColor;
  }
}

.has-warning .form-control, .has-warning .form-control:focus, .form-control.has-warning, .form-control.has-warning:focus {
  border-color: $warningColor;
}

.has-warning{
  .form-text{
    color: $warningColor;
  }
}

.form-group .input-group .input-group-append .btn{
  box-shadow:none !important;
}


// CUSTOM FORM
.form-container{
  .form-vertical{
    display: flex;
    flex-wrap: wrap;
    margin:0 -.5rem;
    .form-item, .btnContainer-outer{
      width:100%;
      padding:0 .5rem;
    }
    .form-item-text{
      margin-top:30px;
      align-self: flex-start;
      @media screen and (max-width: $breakR_s) {
        margin-top:0px;
        margin-bottom:15px;
      }
    }


    &.items-two{
      .form-item{
        width:50%;
      }
    }

    &.items-three{
      .form-item{
        width:33.3333%;
      }
    }

    &.items-four{
      .form-item{
        width:25%;
      }
    }

    &.items-five{
      .form-item{
        width:20%;
      }
    }

    &.items-six{
      .form-item{
        width:16.6666%;
        @media screen and (max-width: $breakR_l) {
          width:25%;
        }
        @media screen and (max-width: $breakR_m) {
          width:33.3333%;
        }
      }
    }

    @media screen and (max-width: $breakR_m) {

      &.items-three, &.items-four, &.items-five, &.items-six{
        .form-item{
          width:50%;
        }
      }

    }

    @media screen and (max-width: $breakR_s) {

      &.items-two, &.items-three, &.items-four, &.items-five, &.items-six{
        .form-item{
          width:100%;
        }
      }

    }


    &.form-horizontal{

      .form-item{
        .form-group{
          width:100%;
          display:flex;

          @media screen and (max-width: $breakR_s) {
            flex-wrap: wrap;
          }

          .label, label{
            width:250px;
            flex-shrink:0;
          }

          .input-container{
            width:100%;
            padding-left:1rem;
          }

          @media screen and (max-width: $breakR_s) {
            .label, label, .input-container{
              width:100%;
              padding-left:0;
            }
          }

        }
      }

      .btnContainer-outer{
        padding-left:274px;
        @media screen and (max-width: $breakR_s) {
          padding:0 .5rem;
        }
      }

    }
  }
}

.form-item .form-item{
  width:100% !important;
  margin:0 !important;
  padding:0 !important;
  .form-group{
    width:100% !important;
    margin:0 !important;
    padding:0 !important;
  }
}

.form-vertical.more-customized{
  form{
    width:100%;
    .formOuter{
      width:100%;
      display:flex;
      flex-wrap:wrap;
    }
  }
  .page-title-right-action{
    margin:0 .5rem;
  }
}

// END CUSTOM FORM

// END FORMS

// TABS

// tab style

.tab-outer {
  border-bottom: 2px solid $primaryColor;

  &.noBorder {
    border-bottom: 0;
  }

}

.nav-tabs > li > a, .nav-pills > li > a {
  margin: 0 !important;
  padding: 8px 15px;
  font-family: $ff_opensansSemiBold;
  display:block;
}

.nav-tabs {
  margin-left: -5px;
  margin-right: -5px;
  padding:0;
}

.nav-tabs > li {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.nav-tabs {
  border-bottom: 0 !important;
}

.nav-tabs > li > a {
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 2px 2px 0 0;
  text-decoration: none;
}

.nav-tabs.boxed > li > a {
  border-color: $secondaryColor;
  background-color: $avatarColor;
  border-bottom: none;
  color: $secondaryColor;
  border-radius: $radius_4t;
}



.nav-tabs.boxed > li > a{
  &:focus, &:hover{
    background: $primaryColor;
    color: $primaryButtonColor;
  }

  &.active{
    color: $reverseTextColor;
    background-color: $primaryColor;
    border-color: $primaryColor;
  }
  &.disabled{
    border-color: $secondaryColor;
    background-color: $avatarColor;
    opacity:0.65;
    cursor: not-allowed;
  }
}


// end tab style

// line style

.tab-outer-lines {
  border-bottom: 1px solid $neutralColorBorderLight;
}

.nav-lines {
  padding:0;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom:-1px;
  font-family: $ff_opensansSemiBold;
}

.nav-lines > li {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.nav-lines {
  border-bottom: 0
}

.nav-lines > li > a {
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  padding: 7px 0;
  line-height: 20px;
  color: $secondaryButtonColor;
  text-decoration: none;
  background-color: transparent !important;

  &:hover, &:focus{
    color: $primaryColor;
  }

  &.active{
    color: $primaryColor;
    border-top-color: transparent;
    border-bottom-color: $primaryColor;
  }

  &.disabled{
    opacity:0.65;
    cursor: not-allowed;
  }
}



// end line style

// END TABS


/* FILTER TAGS */

.filterTags{
  margin:-5px;

  .tag{
    display:inline-block;
    border:1px solid $neutralColorBorder;
    border-radius:1rem;
    padding:$padding_5_10;
    padding-right:35px;
    color:$bodyTextColor;
    height:2rem;
    margin:5px;
    vertical-align: middle;
    background: $tertiaryColor;
    box-shadow:none;

    &.clearAll{
      padding:$padding_5_10;
    }

    &.fixed{
      padding:$padding_5_10;
      background: $neutralColorBackground;
      .close{
        display:none;
      }
    }

    .close{
      float:right;
      margin-right:-25px;
      width:20px;
      height:20px;
      color:$bodyTextColor !important;
      font-size:1rem;
      line-height:20px;
      opacity:1;
      font-weight:bold !important;
    }
  }
}

/* END FILTER TAGS */


/* FILE UPLOADER TAGS */

.fileUploaderTags{
  margin:-5px;
  .tag{
    display:inline-block;
    border:1px solid $neutralColorBorder;
    border-radius:$radius_4;
    padding:5px 45px 5px 30px;
    color:$bodyTextColor;
    height:2rem;
    margin:5px;
    vertical-align: middle;
    background: $tertiaryColor;
    box-shadow:none;

    &.clearAll{
      padding:$padding_5_10;
    }

    .close{
      float:right;
      margin:-5px -45px -5px 0;
      width:35px;
      height:30px;
      color:$bodyTextColor !important;
      font-size:1rem;
      line-height:20px;
      opacity:1;
      padding-top:1px !important;
      border:1px solid $neutralColorBorder;
      border-width:0 0 0 1px;
      font-weight:bold !important;
    }
    .fileIcon{
      width:20px;
      height:20px;
      float:left;
      margin-left:-25px;
      display:inline-block;
      text-align:center;
      line-height:20px;
      font-size:1rem;
      border-radius:100%;
      background: $bodyTextColor;
      color:$reverseTextColor !important;
      &.pdfFile{
        background:$file_pdf;
      }
      &.imageFile{
        background:$file_image;
      }
    }
  }
}

/* END FILE UPLOADER TAG */


// PAGINATION

.pagination {
  display: inline-block;
  margin:0 -5px;

  .pager_links, .np_links {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    margin-right: 5px;
  }

  .btn {
    padding: 10px 6px;
  }
}

// END PAGINATION

// ICON SIZE

.iconSize {
  font-size: 24px;
  line-height: 24px;
  display: block;
}

.iconSize1 {
  font-size: 24px;
  line-height: 18px;
  display: block;
}

.iconSize2 {
  font-size: 40px;
  line-height: 28px;
  display: block;
}

.btn{
  &.iconSize, &.iconSize1, &.iconSize2{
    display:inline-block;
  }
  &.iconSize{
    line-height:21px;
  }
}

// END ICON SIZE


// END PATIENT DEMOGRAPHICS

.demographics {
  .dListCont {

    .dList {
      display: block;
      margin:0;

      a{
        text-decoration: none;
        &:hover, &:focus, .link-text{
          text-decoration: none !important;
        }
      }

      em {
        display: inline-block !important;
        margin-right: 3px;
        font-style: italic !important;
        color: $labelColor;
      }

      span {
        display: inline-block;
      }

    }
  }

  .dListCont.inline {
    margin-left: -5px;
    margin-right: -5px;
    margin-bottom:-3px;

    .dList:not(.d-block) {
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;

      @media screen and (max-width: $breakR_s) {
        &.break-small{
          display:block;
        }
      }
    }
  }

}


.patDemo.list--mt5 .dListCont {
  .dList + .dList {
    margin-top:5px;
  }
}

.demographics-patient, .demographics{
  &.widthImg48 {
    padding-left: 60px;

    .widthImg48 {
      margin-left: -60px;
    }
  }


  &.widthImg48-sm {
    @media screen and (max-width: $breakR_m) {
      padding-left: 60px;
    }
  }

  .dName {
    font-family: $ff_opensansSemiBold;
    color: $titleColor;
    margin:0 0 3px 0;

    .listName {
      color: $linkTextColor;
      font-size: 0.875rem;
      line-height: 1.25rem;
      text-decoration: underline;
      padding: 0;
      height: auto;
      box-shadow: none;

      &:hover {
        text-decoration: none;
      }
    }

  }
  .dList{
    margin:0 0 3px 0;
  }
}


.demographics-tabular{
  table{
    margin:0;
    border:0 !important;

    tr, td, th{
      border:0 !important;
    }

    td{
      padding:0 5px 3px 5px;
      vertical-align: top;

      &:first-child{
        width:150px;
      }

      em{
        font-style: normal !important;
      }

    }
  }
  &.td_padding_5{
    table{
      td{
        padding:5px;
      }
    }
  }

  &.bold-label{
    table{
      td{
        em{
          font-style: normal !important;
          font-family:$ff_opensansSemiBold;
          color: $bodyTextColor;
        }
      }
    }
  }
}


// END PATIENT DEMOGRAPHICS


// ACCORDION

.accordion-outer {

  .accordion {

    .card {
      margin-bottom: $margin_bottom_15;
      border: 1px solid $neutralColorBorderLight;
      border-radius: $radius_4;

      .card-body{
        padding:$padding_15;
      }

      .card-header {
        padding: $padding_15;

        background: $neutralColorBackground;
        border-color: $neutralColorBorderLight;

        div.h2, h2 {
          line-height:20px;
          .btn {
            height: 20px;
            padding-right:45px;

            &::after{
              font-family: 'Minerva2' !important;
              line-height: 1.3;
              display: inline-block;
              content: '\28';
              position: absolute;
              right: 15px;
              top: 18px;
              font-size: 1.5rem;
              color: $bodyTextColor;
              transition: all linear .2s;
            }
            &.collapsed{
              &::after{
                transform: rotate(180deg);
              }
            }

          }

          .btn-link {
            text-decoration: none;
            color: $titleColor;
          }
        }
      }

    }

    &.condensed{
      .card {
        margin-bottom: $margin_bottom_10;
        .card-header {
          padding: $padding_10_15;
          h2 .btn{
            &::after{
              top: 5px;
            }
          }
        }
      }
    }
  }
}
.accordion-overflowVisible {
  .accordion>.card {
    overflow: visible;
  }
}

// END ACCORDION


// TABLE

.dataGridFilter{
  max-width:450px;
}

// CUSTOM TABLE STYLE 001

.customTable{
  margin-bottom:1px;
  .customTable-row{
    border:1px solid $neutralColorBorderLight;
    display:flex;
    margin-bottom:-1px;
    background: $reverseColor;
    position: relative;

    @media screen and (max-width: $breakR_m) {
      flex-direction: column;
    }

    .customTable-cell{
      width:300px;
      padding:10px;
      line-height:20px;
      border:1px solid $neutralColorBorderLight;
      border-width:0 1px 0 0;

      &:last-child{
        border:0;
      }
      &.td_100{
        width:100px;
        flex-shrink: 0;
      }
      &.td_150{
        width:150px;
        flex-shrink: 0;
      }

      &.checkbox-cell{
        width:80px;
        flex-shrink: 0;
        text-align:center;
        vertical-align: top;
        @media screen and (max-width: $breakR_m) {
          margin-top:-3px;
          width:100%;
        }

        .form-check{
          width:20px;
          height:20px;
          display:block;
          margin:auto;

          @media screen and (max-width: $breakR_m) {
            left: 15px;
            top: 15px;
            position: absolute;
          }

          input{
            margin:0;
            left:0;
          }

        }
      }

      @media screen and (max-width: $breakR_m) {
        border:0;
        width:100%;
        padding:0;

        .sm-right-action-position{
          position: absolute;
          right:15px;
          top:15px;
        }

      }


    }
  }

  .customTable-header{
    .customTable-row{
      background: $neutralColorBackground;
      font-family: $ff_opensansSemiBold;
      color: $titleColor;
    }
    @media screen and (max-width: $breakR_m) {
      display:none;
    }
  }

  .customTable-body {
    .customTable-row {
      &:not(.noEff):hover{
        background: $neutralColorBackground;
      }
      @media screen and (max-width: $breakR_m) {
        padding:15px;
        margin-top: 15px;
        box-shadow: $shadow_box2;
        border:0 !important;
        border-radius:$radius_4;
      }
    }
  }

  @media screen and (max-width: $breakR_m) {
    &.with-left-checkbox {
      .customTable-body {
        .customTable-row {
          padding-left: 45px;
        }
      }
    }

    &.with-right-action {
      .customTable-body {
        .customTable-row {
          padding-right: 60px;
        }
      }
    }
  }

}

// END CUSTOM TABLE STYLE 001

// STYLE 001 - LIMITED FLUID USING TABLE

.sm-right-action-position{
  display:inline-block;
}

.table-bordered>thead>tr>th, .table-bordered>thead>tr>td {
  border-bottom-width: 1px;
}

.tableLayout, .tableLayoutSM, .tableLayoutXS {


  margin-left: -15px;
  margin-right: -15px;

  &.noMargin {
    margin-left: 0px;
    margin-right:0px;
    border:1px solid $neutralColorBorderLight;
    border-width:0 1px;
  }

  table {
    width: 100%;
    border: 1px solid $neutralColorBorderLight;
    border-width: 1px 0 0 0;
    table-layout: fixed;
    line-height: $lh_m;

    td{
      background: $reverseColor;
    }
    td, th {
      padding: 10px;
      border: 1px solid $neutralColorBorderLight;
      border-width: 0 0 1px 1px;
      vertical-align: top;
      word-wrap: break-word;

      &.td_100{
        width:100px;
      }
      &.td_150{
        width:150px;
      }
    }

    tr td:first-child, tr td.first, tr th:first-child, tr th.first,
    tr td:first-child[style*="display: none"] + td, tr td:first-child[style*="display:none"] + td,
    tr td.first[style*="display: none"] + td, tr td.first[style*="display:none"] + td,
    tr th:first-child[style*="display: none"] + th, tr th:first-child[style*="display:none"] + th,
    tr th.first[style*="display: none"] + th, tr th.first[style*="display:none"] + th {
      border-left-width: 0 !important;
    }

    tr td:last-child, tr td.last, tr th:last-child, tr th.last {
      border-right-width: 0 !important;
    }

    thead {
      border-width: 0 !important;
      background: $neutralColorBackground;
      font-family: $ff_opensansSemiBold;
      color: $titleColor;
      th{
        font-weight: normal;
      }
    }

    tr:not(.noEff):hover td {
      background: $neutralColorBackground;
      background-clip: padding-box;

      .ellipDot {
        background: $neutralColorBackground;
      }

    }

    tr.deadPatient, tr.deadPatient:hover {

      a.nameToolTip, .nameToolTip {
        color: $reverseTextColor !important;
      }

      td {
        background: $neutralColorBackground;
      }

      .ellipDot {
        background: $neutralColorBackground;
      }

    }
    tr.blackListedPatient, tr.blackListedPatient:hover {
      td {
        background: $reverseTextColor;
      }
    }

  }

  table + table {
    margin-top: -1px;
  }
  table.mt__1{
    margin-top: -1px;
    @media screen and (max-width: $breakR_m) {
      margin-top: 15px;
    }
  }

  .falseTable{
    margin-top: -1px;
  }

  .unreadList{
    tr {
      background: $reverseTextColor;

      td{
        background: $reverseTextColor;
      }

      &:hover{
        background: $neutralColorBackground !important;

        td{background: $neutralColorBackground !important;
        }
      }
    }
  }

  td .text-label {
    font-style: italic;
    font-family: $ff_opensans;
    margin-right:3px;
    color: #706784;
  }

}

.tableLayoutOuter{
  &.with-left-checkbox{
    .tableLayout, .tableLayoutSM, .tableLayoutXS{
      table{
        td.checkbox-cell, th.checkbox-cell{
          width:80px;
          text-align:center;
          vertical-align: top;

          .form-check{
            width:20px;
            height:20px;
            display:block;
            margin:auto;

            @media screen and (max-width: $breakR_m) {
              left: 15px;
              top: 15px;
              position: absolute;
            }

            input{
              margin:0;
              left:0;
            }

          }
        }
      }
    }
  }

  &.noLeftRightBorder {
    table{
      tr{
        td, th{
          border-left-width: 0 !important;
          border-right-width: 0 !important;
        }
      }
    }
  }
}


@media screen and (max-width: $breakR_m) {

  .tableLayout {
    position: relative;
    border:0 !important;
  }

  .tableLayout > table > tbody > tr{
    position: relative;
    display:block;
    border: 0 !important;
    // border-radius:$radius_4;
    box-shadow: $shadow_box2;
    // overflow: hidden;
  }

  .tableLayout > table > tbody > tr + tr{
    margin-top:15px;
  }


  .tableLayout > table > tbody > tr > td, .tableLayout table.w100--sm > tbody > tr > td {
    display: block;
    width: 100% !important;
    border-width: 0 !important;
    padding:0px 15px !important;
  }






  .tableLayout {

    table, table.w100--sm {
      border-width: 0;

      thead {
        display: none !important;
      }

      tr:first-child {
        border-top: 0;
      }

      tr {
        border-bottom: 0;

        td.first, td:first-child {
          padding-top: 10px !important;
        }

        td.last, td:last-child {
          padding-bottom: 10px !important;
        }

      }
    }


    table.subTables {

      thead {
        display: table-row-group !important;
      }

    }
  }



  .sm-right-action-position{
    position: absolute;
    top:15px;
    right:15px;
  }



  .tableLayoutOuter{
    &.with-left-checkbox{
      .tableLayout > table > tbody > tr > td {
        padding-left: 45px !important;
      }
    }

    &.with-right-action{
      .tableLayout > table > tbody > tr > td {
        padding-right: 60px !important;
      }
    }

    &.noLeftRightBorder{
      .tableLayout{
        margin-left:0px;
        margin-right:0px;
      }
    }

  }

}

table.subTables {
  margin: 0;
}

.dropdown-toggle::after {
  display: none;
}


// STYLE 001 - LIMITED FLUID USING TABLE ends


// TOOLTIPS

// TABULAR TOOLTIP

.tabularTooltip, .textTooltip{
  color:$reverseTextColor;
  position: relative;
  display:inline-block;

  a.tooltipLink, button.tooltipLink{
    color:$reverseTextColor;
    text-decoration: none !important;
    white-space: normal;
    text-align: left;

    .tooltipLinkText{
      color:$tooltipColor;

      &.link-regular{
        color:$primaryColor;
        text-decoration: underline;
      }
    }
    &:hover, &:focus, &:active{
      .tooltipLinkText:not(.noUnderline){
        text-decoration: underline;

        &.link-regular{
          color:$primaryColor;
          text-decoration: none;
        }

      }
    }


    span {
      font-family: $ff_opensans;
    }

  }

  .arrowUp{
    margin:-8px 10px 0 10px;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid $tooltipColor;
    float:left;
  }

  .tabularTooltip-box{

    width: 500px;
    padding-top:8px;
    position: absolute;
    left:0;
    display:none;
    z-index: 11;
    @media screen and (max-width: $breakR_s) {
      width: 350px;
    }

    @media screen and (max-width: $breakR_xxs) {
      width: 250px;
    }

    .arrowUp{
      border-bottom: 8px solid $tooltipHeadColor;
    }

    .tabularTooltip-box-inr{
      background:$tooltipColor ;
      border-radius:$radius_4;
      box-shadow:$shadow_tooltip;
      width:100%;
      overflow:hidden;
      overflow-x:auto;
      line-height:$lh_s;
    }

    .tabularTooltip-box-inr2{

      .tooltip-head{
        background: $tooltipHeadColor;
        border-bottom:1px solid $tooltipHeadColor;
        padding:$padding_10_15;
        font-family:$ff_opensansSemiBold;
      }

      .tooltip-list{
        border-bottom:1px solid $tooltipHeadColor;
        padding:$padding_10_15;
      }

    }


  }

  .textTooltip-box{
    width: 360px;
    padding-top:8px;
    position: absolute;
    left:0;
    display:none;
    z-index: 11;

    @media screen and (max-width: $breakR_s) {
      width: 250px;
    }

    @media screen and (max-width: $breakR_xxs) {
      width: 250px;
    }

    *{
      color: $reverseTextColor;
    }

    &.w-250{
      width:250px;
    }

    .textTooltip-box-inr{
      background:$tooltipColor ;
      border-radius:$radius_4;
      box-shadow:$shadow_tooltip;
      width:100%;
      overflow:hidden;
      line-height:$lh_s;
      padding:$padding_10_15;
    }

  }

  span.textTooltip-box {
    display: none !important;
  }


  a.tooltipLink:focus, a.tooltipLink:hover,button.tooltipLink:focus, button.tooltipLink:hover, button.tooltipLink:active, div.tooltipLink:focus, div.tooltipLink:hover, div.tooltipLink:active{
    .tabularTooltip-box, .textTooltip-box{
      display:block;
    }

    span.textTooltip-box {
      display: block !important;
    }
  }

  &.reverse{
    .arrowUp{
      float:right;
    }
    .tabularTooltip-box, .textTooltip-box{
      left:auto;
      right:0;
    }
    &.not-small{
      @media screen and (max-width: $breakR_s) {
        .arrowUp {
          float: left;
        }
        .tabularTooltip-box, .textTooltip-box {
          left: 0;
          right: auto;
        }
      }
    }
  }

}

.tooltipLabelText{
  .tooltipLabel{
    color: $reverseTextColor !important;
    opacity: .85;
  }
  .tooltipText{
    color: $reverseTextColor;
    margin-top:3px;
  }
}

.tooltipLabelText + .tooltipLabelText{
  margin-top:10px;
}

// END TOOLTIPS


// FOOTER

.inrFooter{
  padding:$padding_10_15;
  background: $tooltipColor;
  color:$reverseTextColor;
  text-align: center;
  position: fixed;
  left:0;
  right:0;
  bottom:0;
}
.inrFooterSpace{
  height:55px;
}

// END FOOTER


// MARGIN

//top
.mt_1 {
  margin-top: -1px !important;
}

.mt_2 {
  margin-top: -2px !important;
}

.mt_5 {
  margin-top: -5px !important;
}

.mt_10 {
  margin-top: -10px !important;
}

.mt_15 {
  margin-top: -15px !important;
}

.mt_3 {
  margin-top: -3px !important;
}

.mt3 {
  margin-top: 3px
}

.mt5 {
  margin-top: 5px
}

.mt10 {
  margin-top: 10px
}

.mt15 {
  margin-top: 15px !important
}

.mt20 {
  margin-top: 20px
}

.mt25 {
  margin-top: 25px
}

.mt50 {
  margin-top: 50px
}

//right
.mr3 {
  margin-right: 3px
}

.mr-5 {
  margin-right: -5px
}

.mr5 {
  margin-right: 5px
}

.mr10 {
  margin-right: 10px
}

.mr15 {
  margin-right: 15px
}

.mr20 {
  margin-right: 20px
}

.mr25 {
  margin-right: 25px
}

.mr50 {
  margin-right: 50px
}

.mr_10 {
  margin-right: -10px
}

//bottom
.mb0 {
  margin-bottom: 0px !important;
}

.mb3 {
  margin-bottom: 3px
}

.mb5 {
  margin-bottom: 5px
}

.mb10 {
  margin-bottom: 10px
}

.mb15 {
  margin-bottom: 15px
}

.mb20 {
  margin-bottom: 20px
}

.mb25 {
  margin-bottom: 25px
}

.mb50 {
  margin-bottom: 50px
}

.mb_5 {
  margin-bottom: -5px !important;
}

.mb_10 {
  margin-bottom: -10px !important;
}

.mb_15 {
  margin-bottom: -15px !important;
}
//left
.ml_5 {
  margin-left: -5px;
}

.ml3 {
  margin-left: 3px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml20 {
  margin-left: 20px;
}

.ml25 {
  margin-left: 25px;
}

.ml50 {
  margin-left: 50px;
}

.ml_10 {
  margin-left: -10px;
}

//all
.m0 {
  margin: 0;
}

.m_5 {
  margin: -5px;
}

.m5 {
  margin: 5px;
}

.m10 {
  margin: 10px;
}

.m15 {
  margin: 15px;
}

.m20 {
  margin: 20px;
}

.m25 {
  margin: 25px;
}

.m50 {
  margin: 50px;
}

// END MARGIN


// PADDING
.p15{
  padding:15px;
}

.pb5{
  padding-bottom:5px;
}

.pb10{
  padding-bottom:10px;
}

.pb15{
  padding-bottom:15px;
}

.pb0{
  padding-bottom:0;
}

@media screen and (max-width: $breakR_m) {
  .m-pt0 {
    padding-top: 0;
  }
}

// END PADDING


// BACK BUTTON

.backButton {
  font-size:1rem;
  vertical-align: top;
  margin-right: 2px;
  margin-top: 0;
  cursor: pointer;
  margin-top: -2px;
  @media screen and (max-width: $break_m) {
    font-size:1rem;
  }

  @media screen and (max-width: $break_s) {
    font-size:1rem;
  }
}

.backButtonOuter {
  padding-left: 30px;

  @media screen and (max-width: $break_s) {
    padding-left: 24px;
  }

  &.noPadding{
    padding-left: 30px;
  }


  h1, .h1 {
    margin: 0;
    color: $neutralColor;

    .backButton {
      font-size:1rem;
      margin-left: -30px;
      margin-top: 0;
      display: block;
      float: left;
      color: $neutralColor;
      line-height: 24px;
      height: 24px;
      text-decoration: none !important;
      position: relative;
      position: absolute;

      i{
        position: absolute; width:10px; height:10px; opacity: 0; z-index: -1;
      }

      @media screen and (max-width: $break_m) {
        font-size:1rem;
      }

      @media screen and (max-width: $break_s) {
        margin-left: -24px;
        font-size:1rem;
      }

    }

  }

  .backButton {
    margin-left: -30px;
    margin-top: 0;
    display: block;
    float: left;
    line-height: 24px;
    height: 24px;

    @media screen and (max-width: $break_s) {
      margin-left: -24px;
    }

  }

  .backButton2{
    font-size:$fs_xxxl;
    line-height:24px;
    margin-top:3px;
    text-decoration: none !important;
  }

}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  color: $neutralColor;
}

// END BACK BUTTON

// INPUT GROUP

.input-group {
  &.withSpace {
    .form-control {
      border-radius: $radius_4 !important;
    }

    .input-group-append {
      .btn, button {
        margin-left: 10px;
        border-radius: $radius_4 !important;
      }
    }

  }

}

// END INPUT GROUP


// PATIENT BANNER

.patientBanner {
  background:$reverseColor;
  padding:$padding_15;
  margin-top: $margin_top_15;
  margin-left: $margin_left_15;
  margin-right: $margin_right_15;
  position: relative;
  box-shadow: $shadow_box;

  .patInfo{
    padding-left:74px;

    .avatar{
      margin-left:-74px;
      float:left;
    }
    .row1.d-flex{
      padding-top:2px;
      @media screen and (max-width: $breakR_m) {
        flex-direction: column;
        .demographics{
          margin-top:2px;
        }
      }

      @media screen and (max-width: $breakR_s) {

      }

    }

    .patName{
      font-size:$fs_xxl;
      color:$primaryColor;
      font-family:$ff_opensansSemiBold;
    }

    .row2{
      padding-top:10px;
      margin-top:10px;
      border-top:1px solid $neutralColorBorderLighter;
    }

  }

}

.patientBannerNav{
  background:$reverseColor;
  padding:$padding_10_15;
  margin-top: -1px;
  margin-left: $margin_left_15;
  margin-right: $margin_right_15;
  position: relative;
  padding-right:250px;
  box-shadow: 0px 2px 3px 0px rgb(0 0 0 / 15%);
  border-top:1px solid $neutralColorBorderLighter;

  @media screen and (max-width: $breakR_m) {
    padding-right:180px;
  }

  @media screen and (max-width: $breakR_xs) {
    padding-right:115px;
  }

  .btn-link{
    height:auto;
    font-family:$ff_opensans;
  }
  .patBannerNav{
    width:100%;
    float:left;
    .navbar{
      padding:0;
      button:not(.dropdown-item){
        padding:3px 15px;
        border-radius:13px;
        color:$titleColor;
        text-decoration: none;
        display:block;
        box-shadow:none !important;
        &:hover, &:focus{
          color:$titleColor;
          background:$neutralColorBackground;
          cursor: pointer;
        }
        &:active, &.active{
          color:$reverseColor;
          background:$primaryColor;
        }

      }
      .more-item{
        font-size:36px;
        height:20px;
        line-height:24px;
        display:block;
        margin-top: -2px;
      }

      .navbar-nav{
        padding:0;
        flex-wrap: wrap;
      }

    }
  }
  .patBannerAction{
    float:right;
    margin-right:-235px;
    margin-top:3px;
    @media screen and (max-width: $breakR_m) {
      margin-right:-165px;
    }
    @media screen and (max-width: $breakR_xs) {
      margin-right:-100px;
    }
  }

}

.navbar a:hover, .dropdown:hover .dropbtn{
  background: $neutralColorBackground;
}

// END PATIENT BANNER


// BOXES

.box-lighter {
  border:1px solid $neutralColorBorderLighter;
}

.box-light {
  border:1px solid $neutralColorBorderLight;
}

.box-dark {
  border:1px solid $neutralColorBorder;
}

.border-dashed{
  border-style: dashed;
}

.border-dotted{
  border-style: dotted;
}

.box-shadow-1{
  padding:$padding_15;
  box-shadow: $shadow_box2;
  margin-bottom:1rem;
  border-radius: $radius_4;
}

@media screen and (max-width: $breakR_m) {
  .md-card-style{
    box-shadow: $shadow_box;
    border:0 !important;
    border-radius:$radius_4;
  }
}

// END BOXES


// PADDING

.p-5{
  padding: $padding_5;
}

.p-10{
  padding: $padding_10;
}

.p-15{
  padding: $padding_15;
}

.p-5-10{
  padding: $padding_5_10;
}

.p-10-15{
  padding: $padding_10_15;
}


// END PADDING

// BOX SHADOW

.shadow-box{
  box-shadow:$shadow_box;
}

// END BOX SHADOW




// NO UL STYLE

.noUL{
  list-style: none;
  margin:0;
  padding:0;
  li{
    margin:0;
    padding:0;
  }
  &.listItem{
    li + li{
      margin-top:10px;
      padding-top:10px;
      border-top:1px solid $neutralColorBorderLighter;
    }
  }
}

// END NO UL STYLE


// PAGE TITLE

.page-title{
  display:flex;
  justify-content: space-between;
  position: relative;

  .back-button{
    width:34px;
    flex-shrink: 0;
    padding-top:2px;

    a, button{
      width:28px;
      height:28px;
      background: transparent;
      border-radius:100%;
      text-align: center;
      display:block;
      border:0 !important;
      box-shadow:none !important;
      padding:0 !important;
      .fIcon{
        color: $neutralColor;
        font-size: 1.75rem;
        margin:1px 0 0 -1px
      }
    }

  }
  .page-title-text{
    width:100%;
    h1{
      margin:0;
      &:focus{
        outline:0 !important;
      }
    }
  }

  .page-title-right-action{
    flex-shrink:0;
  }

  @media screen and (max-width: $breakR_s) {
    .page-title-text{
      margin-top:3px;
    }
    h1{
      font-size:1.25rem;
      line-height:32px;
    }
    .back-button{
      width:32px;
      a, button{
        width:24px;
        height:24px;
        margin: 0;
        margin-top: -2px;
      }
    }
  }

}

// END PAGE TITLE


// COMPLETE PAGE STYLE

.left-back-button{
  width:32px;
  flex-shrink: 0;
  padding-top:2px;
  display:none !important;

  a, button{
    width:24px;
    height:24px;
    background: transparent;
    border-radius:100%;
    text-align: center;
    display:block;
    .fIcon{
      color: $neutralColor;
      font-size: 1.375rem;
      line-height: 24px;
    }
  }
}

.outer-box{
  display:flex;

  .outer-box-left{
    width:230px;
    flex-shrink: 0;
    padding-right:1rem;

    .resetBtn{
      margin-left:auto;
      margin-top:5px;
    }

  }

  .outer-box-right{
    width:100%;

    &.cardOuter, .cardOuter{
      margin:0;
    }
  }
}

// filter
.accordion-outer.accordion-outer .accordion.condensed{
  .card{
    margin-bottom:15px;
    border:0;
    background: none;
    border-radius:0;
    .card-header{
      padding:5px 0;
      border:0;
      background: none;

      .btn{
        font-size:1rem;
        text-transform: uppercase;
      }

      h2 .btn::after{
        top: 2px;
        font-size: 1.25rem;
        right: 0;
      }

    }
    .card-body{
      padding:10px 0 0 0;
    }
  }
}
@media screen and (max-width: $breakR_m) {
  .outer-box {

    .outer-box-left-shadow {
      position: fixed;
      z-index: 99;
      top: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, .5);
      transition: all linear .2s;
    }

    .outer-box-left {
      position: fixed;
      z-index: 100;
      top: 0;
      bottom: 0;
      left: -300px;
      background: $reverseColor;
      padding: 1rem 1.5rem;
      box-shadow: $shadow_box2;
      width: 280px;
      transition: all linear .2s;
      overflow-y: auto;
    }

    &.outer-box-filterOverlay {
      .left-back-button{
        display:block !important;
      }
      .outer-box-left-shadow {
        right: 0;
      }
      .outer-box-left {
        left: 0;
      }
    }
  }
}
body[dir="rtl"] {

  .outer-box .outer-box-left {
    padding-left: 1rem;
    padding-right: 0;
  }

  @media screen and (max-width: $breakR_m) {
    .outer-box {

      .outer-box-left-shadow {
        right: 0;
        left: auto;
      }

      .outer-box-left {
        right: -300px;
        left: auto;
        padding: 1rem 1.5rem;
      }

      &.outer-box-filterOverlay {
        .outer-box-left-shadow {
          left: 0;
        }
        .outer-box-left {
          right: 0;
        }
      }

    }
  }
}
// end filter


// RTL COMMON

body[dir="rtl"] {
  .pull-right {
    float: left;
  }

  .pull-left {
    float: right;
  }
}
// END RTL COMMON


// END COMPLETE PAGE STYLE




//*************************** PAGE SPECIAL STYLE ****************************//



// MY FAMILY

.myFamily-list{
  ul.noUL li.noLI{
    @extend .box-shadow-1;
    border:4px solid $warningColor;
    border-width:0 0 0 4px;
    background: $reverseColor;
    position: relative;
    &.self{
      border-color:$successColor;
    }
  }
}


// rtl support css

body[dir="rtl"] {

  .myFamily-list {
    ul.noUL li.noLI {
      border-width: 0 4px 0 0;
    }
  }

  .right-action-text-smIcon {
    text-align: left;
  }

}

// end rtl support css

// END MY FAMILY


// MY APPOINTMENT

.myAppointment-list{

  ul.noUL li.noLI{
    // padding:$padding_15_0;
    // border-bottom:1px solid $neutralColorBorderLighter;
    @extend .box-shadow-1;
  }

  .myApp-list{
    position: relative;
    .myApp-list-action{
      @media screen and (max-width: $breakR_s) {
        position: absolute;
        right: 0;
        width:auto;
        top:0;
        margin:0 !important;
        text-align:left;
        padding:0 !important;

      }

      .myApp-list-action-margin{
        margin:0 1rem;
        @media screen and (max-width: $breakR_l) {
          margin:0 .5rem;
        }
        @media screen and (max-width: $breakR_m) {
          margin:0 1rem;
        }
        @media screen and (max-width: $breakR_s) {
          margin:0 !important;
          &.dropdown{
            margin:0 !important;
          }
        }
      }

    }
  }

}

.left-icon-spacer-32{
  padding-left:2rem;
  .fIcon{
    margin-top:-2px;
    margin-left:-2rem;
    font-size:1.5rem;
    line-height:1.5rem;
    width:1.5rem;
    height:1.5rem;
    text-align:center;
    float:left;
  }
}

// rtl support css

body[dir="rtl"] {

  .left-icon-spacer-32 {
    padding-right: 2rem;
    padding-left: 0;
    .fIcon {
      margin-right: -2rem;
      margin-left: 0;
      float: right;
    }
  }

  @media screen and (max-width: $breakR_s) {
    .myAppointment-list .myApp-list .myApp-list-action {
      left: 0;
      right: auto;
    }
  }

}

// end rtl support css

// END MY APPOINTMENT


// TASK CENTER

.task-center-box{
  display:flex;
  margin-left:-1rem;
  margin-right:-1rem;

  .task-center-box-inner{
    padding-left:1rem;
    padding-right:1rem;
    width:100%;
    border:1px solid red;
    min-height:300px;
    &.right{
      display:none;
    }
  }

  &.splitView{
    .task-center-box-inner{
      width:50%;
      &.right{
        display:block;
      }
    }
  }

}

.task-center-leftTable{
  td{

  }
}


// rtl support css

body[dir="rtl"] {

}

// end rtl support css

// END TASK CENTER


// SIGN UP CARDS

.bg1 {
  background: url(../../../../assets/images/login_leftBg.jpeg) 0 0 no-repeat;
  background-size: cover;
  background-color: $reverseColor;
}

.bg2 {
  background: radial-gradient( ellipse at center, rgba(64, 0, 127, 0.95) 0, rgba(76, 0, 127, 0.95) 100%);
}

.bg3 {
  background: url(../../../../assets/images/login--line-bg.png) 0 0 repeat;
  min-height: 500px !important;
  height: calc(100vh - 50px) !important;
  position: relative;
  padding:30px 15px;

  @media screen and (max-width: $breakR_m) {
    min-height: calc(100vh - 50px) !important;
    height: auto !important;
  }

  @media screen and (max-width: $breakR_s) {
    min-height: calc(100vh - 50px) !important;
    height: auto !important;
  }
}

.signup-card {
  width: 850px;
  background: $neutralColorBackgroundLight;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 7px 0 rgb(0 0 0 / 40%);
  border-radius: 3px;
  overflow: hidden;

  @media screen and (max-width: $breakR_l) {
    width: 100%;
    position: static;
    transform: none;
  }


  @media screen and (max-width: $breakR_s) {
    width: 100%;
    margin: auto;
    position: static;
    transform: none;
  }

  .signUpC {
    display: table;
    width: 100%;
    table-layout: fixed;

    @media screen and (max-width: $breakR_s) {
      display: block;
    }

    h2,
    h1 {
      @extend .h2;
      line-height: 30px;
      height: 30px;
      padding: 0;
    }

    .sUp--left {
      display: table-cell;
      width: 55%;
      padding: 30px 35px;
      overflow-y: auto;

      @media screen and (max-width: $breakR_s) {
        display: block;
        width: auto;
        padding: 25px;
      }

      .back-button{
        width:34px;
        padding-top:3px;
        margin-top: -6px;
        a{
          width:1.5rem;
          height:1.5rem;
          .fIcon{
            font-size:18px;
          }
        }
      }

      &.signUpC--inrFullSize {
        width: 100%;
        vertical-align: middle;

        @media screen and (max-width: $breakR_s) {
          &.minH--510 {
            height:auto;
            display: table-cell;
          }
        }
      }
    }

    .sUp--right {
      display: table-cell;
      width: 45%;
      background: $neutralColorBackground;
      border: 1px solid $tertiaryColorDark;
      border-width: 0 0 0 1px;
      padding: 30px 35px;
      vertical-align: middle;
      position: relative;

      @media screen and (max-width: $breakR_s) {
        display: none;
        width: auto;
        padding: 25px;
        border-width: 1px 0 0 0;

        &.minH--510 {
          min-height: 0;
        }

      }

      .centerText {
        display: inline-block;
        text-align: center;
        width: 100%;
        padding: 0 20px 20px;

        .img {
          display: block;
          margin: auto;
        }

        .text {
          margin-top: 15px;
          color: $labelColor;
          line-height: 16px;
        }

        .mxht_160 {
          max-height: 160px;
        }
      }

      .stepText {
        position: absolute;
        bottom: 30px;
        left: 0;
        width: 100%;
        text-align: center;

        @media screen and (max-width: $break_xs) {
          position: static;
          margin-top: 15px;
        }

        p {
          display: inline-block;
          margin: 0;
          border-top: 2px dotted $neutralColorBorderLight;
          padding: 7px 0 0 0;

          .dib{
            margin:0 2px;
          }
        }
      }
      &.loginImage{
        padding:0px;
        img{
          height:auto;
          width:100%;
        }
      }
    }

    .sUp--text {
      margin-top: 30px;
      margin-bottom: 0;
    }

    //	opt//
    .signUpOptsNew {
      margin-left: -.5rem;
      margin-right: -.5rem;
      text-align: center;
      display:flex;

      .optC {
        width: 50%;
        padding:0 .5rem;
        display:flex;

        .opt {
          width: 100%;
          height: 150px;
          display: table-cell;
          text-align: center;
          vertical-align: middle;
          border: 1px dashed $neutralColorBorder;
          padding: 35px 10px;
          text-decoration: none;
          background: $neutralColorBackgroundLight;
          color: $neutralColor;
          border-radius: $radius_2;

          .fIcon {
            margin-bottom: 5px;
          }

          &:hover {
            color: $titleColor;
            background: $reverseColor;
          }

          strong{
            display:block;
          }

          .optText {
            line-height: 18px;
            margin-top: 10px;
            display: block;
          }
        }

        .opt.active {
          color: $titleColor;
          background: $reverseColor;
        }
      }


      .optC2{
        width:100%;
        padding:0;
        .page-title-right-action{
          width:100%;
        }
        .btnContainer{
          width:100%;
          margin:0;

          button.opt{
            margin:0 .5rem;
            width:calc(50% - 1rem);
          }
        }
      }


    }
  }
}

.minH--510 {
  height: 510px;

  @media screen and (max-width: $breakR_s) {
    height: auto;
    min-height: 0;
  }

  .signCard-box{
    margin-top:20px;
    max-height:400px;
    overflow:hidden;
    overflow-y: auto;
    @media screen and (max-width: $breakR_s) {
      max-height:5000px;
    }
  }

}

.signCard_mh356 {
  height: 300px;
  margin-bottom: 15px;
}

// END SIGN UP CARDS

// DYNAMIC PASSWORD CHECKLIST

.dynamicPassword-checklist {
  clear: both;
  overflow: hidden;
  list-style: none;
  padding: 10px 0;
  margin: 0;

  .fIcon {
    position: absolute;
    font-weight: 700;
    float: left;
    top: 2px !important;
    left: 0;
    display: inline-block !important;
    margin-right: 8px;
  }

  li {
    display: block;
    list-style: none;
    float: left;
    width: 50%;
    line-height: 1.5em;
    font-size: 1em;
    margin: 0;
    padding: 0;
    overflow: hidden;
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
    margin-bottom: 5px;
    padding-left: 20px;
    position: relative;
  }
}

.sm_reverse {
  @media screen and (max-width: $breakR_s) {
    color: $reverseTextColor;
  }
}

// END DYNAMIC PASSWORD CHECKLIST

/* LOGIN */


.loginContainer{
  width:100%;
  background-color:$reverseColor;
  min-height: 500px;
  height: calc(100vh - 50px);
  display: flex;
  @media screen and (max-width: $breakR_s) {
    min-height: 100%;
    height: auto;
    display: block;
  }

  .leftBox, .rightBox{
    width:50%;
    height:100%;
    @media screen and (max-width: $breakR_s) {
      width:100%;
    }
  }
  .leftBox{
    background: $linkTextColor;
    background-size: cover;
  }
  .rightBox{
    background: none;
    background-size: cover;

    .bgColor{
      background: none;
      height:100%;
    }
    .bgLines{
      background: none;
      height:100%;
    }

  }


}

.reverseColor {
  color: $reverseColor !important;
}


.loginFormOuter, .signUpOuter{
  width:100%;
  height:100%;
  padding:50px;
  display:flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $breakR_s) {
    padding:0
  }

  .loginForm{
    width:400px;
    max-width:100%;
    @media screen and (max-width: $breakR_s) {
      width:100%;
    }
    .loginFormBox{
      background: $reverseColor;
      border:1px solid $neutralColorBorderLighter;
      padding:30px;
      margin-bottom:$margin_bottom_15;
      @media screen and (max-width: $breakR_s) {
        border-radius:$radius_4;
        margin-bottom:30px;
      }
      h1{
        margin-bottom:$margin_bottom_15;
        margin-top:$margin_top_10R;
      }
      .btnContainer{
        margin-top:10px; text-align: right;
        body[dir="rtl"] {
          text-align: left;
        }
      }
    }
  }

  .signUpBox{
    width:400px;
    max-width:100%;
    @media screen and (max-width: $breakR_s) {
      width:100%;
    }
    h2, .subText{
      color:$textOnNeutralBackgroundDark;
    }
    h2{
      @extend .h1, .fs-normal;
    }
    .subText{
      font-size:$fs_l;
      margin:5px 0 15px;
    }

    .btn-secondary, .btn-secondary:focus, .btn-secondary:hover{
      border-color: $reverseColor;
      background: $reverseColor;
      color:$primaryColor;
    }

  }


}

.loginFormOuter{
  background: rgba(0,0,0,.025);
  .loginFormBox{
    border:0 !important;
    box-shadow:0 5px 5px rgba(0,0,0,.05);
  }
}

@media screen and (max-width: $breakR_s) {
  .smBgImg{
    background: url(../../../../assets/images/login_rightBg.jpeg) 0 0 repeat;
    height:auto;
  }
  .smBgColor{
    background: radial-gradient(
                    ellipse at center,
                    rgba(64, 0, 127, 0.95) 0,
                    rgba(76, 0, 127, 0.95) 100%
    );
  }
  .smBgLines{
    background: url(../../../../assets/images/login--line-bg.png) 0 0 repeat;
    min-height:calc(100vh - 50px);
    height:auto;
    padding:30px 15px;
  }

  .smBgImg{
    .leftBox, .rightBox, .bgColor, .bgLines{
      background:none;
      height:auto;
      max-height:auto;
    }

    .rightBox{
      padding-top:0;
    }
  }

  .loginFormBox, .signUpBox{
    .btnContainer{
      margin-left:0;
      margin-right:0;
    }
    .btn-primary{
      width:100%;
      margin:0;
    }
  }
  .signUpBox{
    text-align: center;
  }
  .copyrightText{
    color:$reverseTextColor;
    opacity:.7;
    text-align:center;
    margin-top:30px;
  }
}

.has-hintIcon {
  .fIcon {
    top:9px !important;
  }
}

/* END LOGIN */


// MODAL

.modal{
  overflow-y:auto;
}

.modal-dialog{

  .modal-header, .modal-body, .modal-footer{
    padding:15px;
  }

  .modal-content{
    border:0;
    box-shadow: $shadow_modal;
  }

  .modal-header{
    padding: 15px;
    border:0;
    background: $primaryColor;
    .h2{
      font-size:$fs_xxl;
      line-height:30px;
      color:$titleColor;
      color:$reverseTextColor;
      font-family:$ff_opensansSemiBold;
      margin:0;
    }

    .close{
      font-size:1.5rem;
      line-height:30px;
      padding:15px;
      height:60px;
      margin: -15px;
      opacity:1;
      color:$reverseTextColor;

      span{
        display:block;
        height:30px;
      }
    }
  }

  .modal-footer{
    text-align: right;
    display:block;
    &>*{
      margin:0;
    }
  }


}

// END MODAL


// SPINNER

@-webkit-keyframes rotation {

  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
  }

}

@-moz-keyframes rotation {

  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(359deg);
  }

}

@-o-keyframes rotation {

  from {
    -o-transform: rotate(0deg);
  }

  to {
    -o-transform: rotate(359deg);
  }

}

@keyframes rotation {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }

}

.spinnerOverlay{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background: rgba(255,255,255,.5);
  z-index: 97;
}

.spinner {
  position: fixed;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin: -30px 0 0 -30px;
  z-index: 98;
  -webkit-animation: rotation .9s infinite linear;
  -moz-animation: rotation .9s infinite linear;
  -o-animation: rotation .9s infinite linear;
  animation: rotation .9s infinite linear;
  border-left: 6px solid rgba(4,43,74, .25);
  border-right: 6px solid rgba(4,43,74, .25);
  border-bottom: 6px solid rgba(4,43,74, .25);
  border-top: 6px solid rgba(4,43,74, 1);
  border-radius: 100%;

  &.staticSpinner {
    position: static;
    margin:auto;
  }

  &.smallSpinner {
    height: 40px;
    width: 40px;
  }
}

// END SPINNER


// PATIENT OVERVIEW
.patOverviewCont{
  .patOverview-row{
    margin-left:-8px !important;
    margin-right:-8px !important;
    .patOverview-column{
      padding-left:8px !important;
      padding-right:8px !important;
    }
  }
}
// END PATIENT OVERVIEW

// No Btn Style Starts
.noBtnStyle {
  height: auto;
  background: none;
  padding: 0px;
  box-shadow: none;
  border: 0;
  font-family: $ff_opensans;

  &:hover {
    box-shadow: none;
  }
}
// No Btn Style ends

//Date Picker CSS
.customDatePicker{

  .MuiInputAdornment-root{
    position: absolute;
    right: 13px;

    button{
      border-radius: 0 2px 2px 0;
      height: 36px;
      background-color: $neutralColorBackgroundLight;
      border-left: 1px solid $neutralColorBorder;

      &:hover{
        background-color: $neutralColorBorderLighter;
      }
    }
  }

  &.customTimePicker{
    .MuiInputAdornment-root{
      right: 18px;
    }
  }

}

//End Date Picker CSS

// page break

.pageBreak{
  width:100%;
  text-align:center;
  font-size:1rem;
  position: fixed;
  z-index:0;
  top:50%;
  transform: translateY(-50%);

  .image{
    background: url("../../../../assets/images/pageBreak-con.svg") $reverseColor no-repeat center center;
    background-size: 70px;
    width:120px;
    height:120px;
    display:block;
    margin:0 auto 15px;
    border-radius:100%;
    border:1px solid $neutralColorBorderLight;
  }
  .text{
    display: block;
  }
}

// End page break


// No Record for Grid
.noRecordCard {
  min-height: 300px;
  text-align: center;
  position: relative;
  .pageBreak {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }
}
// No Record for Grid

// No Record for Page
.cardOuter.minHeight {
  .noRecordCard {
    margin-top: 100px;
  }
}
// No Record for Page

.form-group{
  .input-container{
    input{
      outline:none !important;
    }
  }
}
// End page break

// Sort by Component starts
.sortBy{
  height: 38px;
  padding: 8px 6px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $bodyTextColor;
  border: 1px solid $neutralColorBorder;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  border-radius: 4px 0 0 4px;
  background: $reverseColor;

  em {
    font-size: $fs_m;
    display: inline-block;
    margin: 0 3px;
    font-style: normal;
    font-family: $ff_opensansSemiBold;
  }

  strong {
    font-weight: normal;
    font-family: $ff_opensans;
  }

  .sortIconBtn {
    padding: 6px 5px;
    border-width: 0 0 0 1px;
    vertical-align: middle;
    border-radius: 0;
    margin: -5px -4px -4px 0;
    height: 32px;
  }

  .sortBtn {
    position: absolute;
    right: -42px;
    top: -1px;

    button, .btn {
      border-radius: 0 4px 4px 0;
    }
  }

  @media screen and (max-width: $break_l) {
    display: none;
  }

}

.sortBy-mobile {
  display: none;
  @media screen and (max-width: $break_l) {
    display: inline-block;
    margin-right: 10px;
  }
}
// Sort by Component ends

// Input with icon component

.inputBtnGroup {

  .input-group > .form-control {
    padding-left: 40px;

    &:focus {
      z-index: 0;
    }
  }

  .preInputIcon {
    position: absolute;
    top:7px;
    left: 7px;
    opacity: 0.7;
  }
}


.unavailableText {
  color: $hintTextColor;
}
// Input with icon component

.lineThrough {
  text-decoration: line-through;
}

// Disabled customization from dropdown and phone input
.selectType .css-1insrsq-control {
  background-color: #e9ecef;
  border-color: $neutralColorBorder;

  input:disabled,
  .css-lr9fot-singleValue {
    color: $bodyTextColor;
  }
}

.form-group fieldset:disabled input.PhoneInputInput {
  background-color: #e9ecef;
}
.selectType input{
  outline: 0 !important;
}
.tosterMargin{
  .alert{
    margin: 15px;
  }
}

.PhoneInputCountry{
  img{
    width: 1.5rem !important;
    vertical-align: top !important;
  }
}
input::-ms-reveal, input::-ms-clear{

  display: none;

}

.widgetData .btn, .widgetData .btn:hover {
  color:$bodyTextColor !important;
}

.widgetData .btn em {
  color:$labelColor !important;
}

button.notificationBlock.w-100:hover, button.notificationBlock.w-100:focus, button.notificationBlock.w-100:active {
  color:$bodyTextColor !important;
  background:transparent !important;
}