@import '../../../ui/comps/src/scssCore/_cssVarsColors';
@import '../../../ui/comps/src/scssCore/_cssVariables';

// Form Listing starts

.formListing {
	.td1 {
		width: 25%;
	}

	.td2 {
		width: 30%;
	}

	.td3 {
	}

	.td4 {
		width: 100px;
	}

	tr {
		@media screen and (max-width: $break_l) {
			td:nth-child(2),
			td:nth-child(3),
			td:nth-child(4) {
				.xs-label {
					display: none !important;
				}
			}
		}
	}
}

// Form Listing ends
.semiBold {
	div {
		font-family: $ff_opensansSemiBold;
	}
}
