// Created by Naved : 01Feb22 07:08

@import '../../scssCore/_cssVarsColors';
@import '../../scssCore/_cssVariables';


.topnav {
  background:#2A4E69;
  box-shadow: $shadow_box;
  padding: 0;
  height: 50px;
  position: fixed;
  z-index:90;
  top:0;
  left:0;
  right:0;

  .navbar-nav{
    padding:0;
  }

  .brandName {
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    margin:10px 0 10px 15px;
    height:30px;
    max-width:200px;
    cursor: pointer;
    float:left;
    &:hover, &:focus, &:active{
      background-color: transparent;
    }
    img{
      height:100%;
      width:auto;
      max-width:100%;
    }
    @media screen and (max-width: $breakR_m) {
      max-width:150px;
    }
    @media screen and (max-width: $breakR_s) {
      max-width:100px;
    }
  }

  .leftNav, .rightNav{

    display:block;
    padding:0;

    .navbar-nav{
      display:block;
    }

    .nav-item{
      float:left;
      .btn,button{
        .fIcon{
          font-weight: normal;
        }
      }
      &.dropdown {
          .dropdown-menu {
            .dropdown-item {
              color: $titleColor;
              background:$reverseColor;
            }
          }
      }
    }

    a,button, button.noBtn-style {
      color: $reverseColor;
      padding:$padding_10_15;
      text-decoration: none;
      line-height:30px;
      display:block;
      border:0;
      height:auto;
      border-radius:0 !important;
      box-shadow:none !important;
      background: #2A4E69;
      margin:0 !important;
      width:100%;
      text-align:left;
      @media screen and (max-width: $breakR_s) {
        padding:$padding_10;
      }

      .threeDot, .rightNavIcon{
        line-height:30px;
        display:block;
        font-size:1.75rem;
        &.inline-block{
          line-height:30px;
          display:inline-block;
          vertical-align: middle;
          line-height:28px;

        }
      }
      .rightNavIcon-text{
        margin-left:5px;
      }
      .threeDot{
        font-size:2.5rem;
      }

      &:hover, &:focus{
        background: #042b4a;
        color: $reverseColor;
        cursor: pointer;
      }

      &.active {
        background-color: #042b4a;
        color: $reverseTextColor;
      }

    }

    .dropdown-menu{
      .nav-item{
        float:none;
      }

      a,button, button.noBtn-style{
        line-height:20px;
        padding:$padding_10_15;
      }
    }

  }


  .rightNav>.navbar-nav>a{
    float:left;
    width:auto;
  }

  .leftNav{
    margin-left:30px;
    float:left;
    width:calc(100% - 570px);
  }

  .rightNav{
    float:right;

    .userName-icon{
      display:none !important;
    }

    .navbar-nav{
      .nav-item:last-child{
        .userName{
          .userName-text::after {
            display: inline-block;
            width: 0;
            height: 0;
            margin-left: 0.255em;
            vertical-align: 0.15em;
            content: "";
            border-top: 0.3em solid;
            border-right: 0.3em solid transparent;
            border-bottom: 0;
            border-left: 0.3em solid transparent;
          }
          .userName-text-inner{
            max-width:100px;
            overflow: hidden;
            text-overflow: ellipsis;
            display:inline-block;
            vertical-align: top;
          }
          @media screen and (max-width: $breakR_m) {
            .userName-icon{
              display:block !important;
            }
            .userName-text{
              display:none;
            }
            .userName-text::after{
              display:none;
            }
          }
        }
      }
    }
  }

  .dropdown-menu{
    border-radius:0 !important;
    margin:0;
    .nav-item{
      display:block;
    }
  }


  .leftNavOverlay{
    display:none;
    position: fixed;
    left:0;
    right:100%;
    left:0;
    top:50px;
    bottom:0;
    background: rgba(0, 0, 0, 0.5);
    transition: all linear 0.2s;
    z-index: 97;
  }

  &.showMobileNav{
    @media screen and (max-width: $breakR_m) {
      .leftNavOverlay {
        display: block;
        right: 0;
      }
    }
  }

}

.topnav .rightNav .nav-item.dropdown .dropdown-menu .dropdown-item:hover {
  background-color:  $primaryColor;
  color: $reverseColor;
}

.topNavSpacer{
  height:50px;
  width:100%;
}

.linkText--login {
  font-style: normal;
  font-size: 14px;
}

.iconSize--login {
  font-size: 1.3em;
  line-height: 20px;
}




.navbar a:hover, .dropdown:hover .dropbtn {
  background-color: $tertiaryColorDark;
}


/******** SIDE NAVBAR STARTS **********/

.sideNav {

  .leftWrap {
    position: static;
    overflow: visible;
    height: auto;
    padding-left: 0px;
    left: 0;
    top: 65px;
    background: $neutralColorBackgroundLight;
  }

  .usrLeftMenu {

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        list-style: none;


        button {
          display: block;
          text-decoration: none;
          background: $reverseColor;
          padding: 15px;
          color: $tooltipColor;
          line-height: 20px;
          font-family:$ff_opensans;
          border: 1px solid $neutralColorBorderLighter;

          &:hover, &.active{
            border-left: 4px solid $primaryColor;
            background-color: $neutralColorBackground;
            color: $linkTextColor;
          }
        }
      }
      li + li{
        margin-top:-1px;
      }
    }

  }
}

.nav-item.dropdown:hover>.noBtn-style{
  background: #042b4a !important;
}

.nav-item.dropdown{
  .nav-item.dropdown{
    .noBtn-style::after {
      transition: all ease-in-out .2s;
      display: block;
      width: 0;
      height: 0;
      content: "";
      position: absolute;
      right:10px;
      top:15px;
      border-left: 6px solid $tooltipColor;
      border-right: 0;
      border-bottom: 5px solid transparent;
      border-top: 5px solid transparent;
      @media screen and (max-width: $breakR_m) {
        right:15px;
        top:17px;
        border-top: 6px solid $tooltipColor;
        border-bottom: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
      }
    }

    &.show{
      .noBtn-style::after {
        transform:rotate(180deg);
      }
    }

    &:hover, &:focus{
      .dropdown-menu{
        display:block;
        position: absolute;
        left:100%;
        top:0 !important;
        transform: none !important;
        margin:0;
        border-radius:0 !important;
      }
    }
  }
}


// RESPONSIVE NAV CLASSES
.topnav{
  padding:0;
  .dropdown-menu{
    .nav-item a{white-space:nowrap;}
  }
}
.threeBars{
  display:none;
  float:left;
}


@media screen and (max-width: $breakR_m) {

  .topnav{
    padding:0;
  }

  .nav-item.dropdown{
    .nav-item.dropdown:hover{
      .dropdown-menu{
        position: static !important;
        display:none;
      }
    }
  }

  .threeBars{

    display: block;
    padding: 16px 15px;
    height: 50px;
    width: 56px;
    margin: 0 -5px 0 0px;
    background: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 0;
    .bar{
      display: block;
      background: $reverseColor;
      height: 3px;
      border-radius:3px;
      transition: all ease-in-out .3s;
    }
    .bar + .bar{
      margin-top:4px;
    }

  }

  .topnav .leftNav{
    transition: all ease-in-out .2s;
    position: fixed;
    top:50px;
    bottom:0;
    left:-350px;
    width:300px;
    z-index: 98;
    margin:0;
    overflow-y:auto;
    background: $reverseColor;
    box-shadow: $shadow_modal;
    display:none;


    .nav-item{
      float:none;
    }


    .dropdown-menu{
      margin:0;
      border-radius:0;
      width:100%;
      border:0;
      box-shadow:none;
      transform:none !important;
      @media screen and (max-width: $breakR_m){
        position: static !important;
      }

      .nav-item{

        &.dropdown{
          .noBtn-style{
            background: $neutralColorBackground !important;
          }
        }

        .dropdown-menu{
          display:none;
          &.show{
            display:block;
          }
          font-family:$ff_opensans;
          .dropdown-item{
            padding-left:30px;
          }
        }
      }

    }

    .threeDots-btn + .dropdown-menu{
      display:block;
    }

    .threeDots-btn{
      display:none !important;
    }

  }

  .topnav.showMobileNav{
    .leftNav{
      display:block;
      left:0;
    }
    .threeBars{
      .bar.one{
        transform: rotate(45deg) translate(3px, 5px);
      }
      .bar.two{
        transform: rotate(-45deg) translate(0px, -2px);
      }
      .bar.three{
        display:none;
      }
    }
  }

}

@media screen and (max-width: $breakR_s){
  .threeBars{
    padding: 17px 15px;
    width: 56px;
    margin: 0 -10px 0 0;
  }
}

// END


/******** SIDE NAVBAR Ends **********/


body[dir='rtl'] {




  .rightNavIcon-text{
    margin-left:0;
    margin-right:5px;
  }

  .topnav {
    padding:0;

    @media screen and (max-width: $breakR_m){
      padding: 0;
    }

    .brandName{
      float:right;
      margin-left:0;
      margin-right:15px;
    }
    .leftNav {
      margin-left: 0px;
      margin-right: 30px;
      float: right;
      .nav-item {
        float: right;
      }
      @media screen and (max-width:$breakR_m){
        left: auto;
        right: -350px;
        margin:0;
        padding:0;

        .nav-item {
          float: none;
        }
      }
    }

    &.showMobileNav .leftNav{
      right: 0px;
    }

    .rightNav > .navbar-nav > a{
      float:right;
    }

    .rightNav {
      float: left;
      .nav-item {
        float: right;
      }

      .navbar-nav .nav-item:last-child .userName .userName-text::after {
        margin-right: 0.255em;
        margin-left:0;
      }

    }
    .dropdown{
      .nav-item {
        float: none;
      }
    }


    .leftNav a, .leftNav button.noBtn-style, .rightNav a, .rightNav button.noBtn-style{
      text-align: right;
    }

    .nav-item.dropdown .nav-item.dropdown .noBtn-style::after{
      right: auto;
      left:10px;
      border-right: 6px solid $titleColor;
      border-left: 0;
      @media screen and (max-width:$breakR_m){
        border-top: 6px solid $titleColor;
        border-bottom: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
      }
    }

    .nav-item.dropdown .nav-item.dropdown:hover .dropdown-menu{
      right: 100%;
      left: auto;
    }

    .threeBars{
      float: right;
      margin: 0 0 0 -10px;
      @media screen and (max-width:$breakR_m){
        margin: 0 0 0 -10px;
      }
      @media screen and (max-width: $breakR_s){

      }
    }




  }




}

.patBannerNav .navbar-expand-sm{
  @media screen and (min-width: $break_xs) {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-nav{
    flex-direction: row;

    .dropdown-menu{
      position: absolute;
      left:0 !important;
      right:auto !important;
    }
  }
}
.skipHeader{
  position: absolute;
  left: 0;
  top: 0;
  z-index: -99;
  opacity: 0;

  &:focus {
    z-index: 99;
    opacity: 1;

  }
}