// Created by Naved : 31Aug22

@import '../../ui/comps/src/scssCore/cssVarsColors';

.aboutUsModal {
	.installedDetails {
		max-height: 150px;
		background: #f2f2f2;
		overflow: hidden;
		overflow-y: auto;
		border: 1px solid #ccc;
		padding: 10px;
	}
}
