@import '../scssCore/_cssVarsColors';
@import '../scssCore/_cssVariables';

.setRemoveBtn{
    .form-item{
        width: 100% !important;
        text-align: right;
    }
    .btn-link{
        color: $errorColor;
        &:hover{
            color: $errorColor;
        }

    }
}
.addLinkMargin{
    .btn{
        margin-top: 5px;
    }
}

/********Select-box color css*********/
.css-26l3qy-menu .css-1n7v3ny-option{

    background:$neutralColorBackground;

}
.css-26l3qy-menu .css-9gakcf-option{

    background:$primaryColor;

}
/********Select-box color css End*********/
.input-container{
   select{
        padding: 8px 9px;
    }
}