.PhoneInputInput {
  border-radius: 0 4px 4px 0;
  border: 1px solid #999999;
  border-left: 0px;
  height: 38px;
  padding: 8px 10px;
  width: calc(100% - 55px);
  display: inline-block;


  &:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
    0 0 8px rgb(102 175 233 / 60%);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  }
}

.PhoneInputCountry {
  margin-right: 0px;
  border: 1px solid #999;
  background: #f7f7f7;
  display: inline-block;
  width: 55px;
  border-radius: 4px 0 0 4px;
  vertical-align: top;
}

.countrySelectElement{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  border: 0;
  opacity: 0;
  cursor: pointer;
}

.countrySelectOption{
  padding: 5px;
  display: block;
}

.PhoneInputCountry {
  div{
    font-size:1rem;
  }
  .fIcon{
    font-weight:bold;
    font-size:14px;
  }
}

.countrySelectElement:focus + div{
  outline: 2px solid #CCA8FF !important;
  outline-offset: -2px;
}
.countrySelectElement + div{
  padding: 9px 5px 7px;
}

.phoneWrapper {
  position: relative;
  padding: 0 !important;
  border: 0 !important;
}